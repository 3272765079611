import React from 'react'

import { Dot, DotsWrap, WithDotsIconRoot } from './WithDotsIcon.styled'

export type WithDotsIconProps = {
  children: React.ReactNode
  greenDot?: boolean
  blueDot?: boolean
  withIcon?: boolean
}

export const WithDotsIcon = ({
  children,
  blueDot,
  greenDot,
  withIcon,
}: WithDotsIconProps) => (
  <WithDotsIconRoot>
    {children}

    {blueDot || greenDot ? (
      <DotsWrap withIcon={withIcon}>
        {blueDot && <Dot />}
        {greenDot && <Dot isGreen />}
      </DotsWrap>
    ) : null}
  </WithDotsIconRoot>
)
