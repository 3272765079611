import * as React from 'react'
import { useStore, useStoreMap } from 'effector-react'

import {
  buildEstimationStatusKey,
  EstimationService,
} from '@gmini/common/lib/Services/EstimationService'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

export interface UseEstimationValue {
  estimationStatus: api.Estimation.Status | null
  xlsxReportUrl: string
  pending: boolean
  error: boolean
  emptyCondition: boolean
  estimation: {
    estimationId: number
    estimationVersion: number
  } | null
}
export interface UseXlsReportParams {
  readonly estimationVersion?: number
  readonly estimationId?: number
  estimationService: EstimationService
  withSubtotals: boolean
}

function useEstimationCalculationStatus({
  estimationKey,
  estimationService,
}: {
  readonly estimationKey: string | null
  readonly estimationId?: number
  estimationService: EstimationService
}): api.Estimation.Status | null {
  return useStoreMap({
    store: estimationService.estimation.estimationStatus$,
    keys: [estimationKey],
    fn(estimationCalculationStatus, [key]) {
      if (!key || !estimationCalculationStatus) {
        return null
      }
      return estimationCalculationStatus[key] || null
    },
  })
}

function useXlsReport({
  estimationVersion,
  estimationId,
  estimationService,
  withSubtotals,
}: UseXlsReportParams): string {
  return useStoreMap({
    store: estimationService.estimation.estimationStatus$,
    keys: [estimationVersion, estimationId, withSubtotals],
    fn(estimationCalculationStatus, [estimationVersion, estimationId]) {
      if (!estimationId || !estimationVersion || !estimationCalculationStatus) {
        return ''
      }
      return estimationCalculationStatus[
        buildEstimationStatusKey({ estimationId, estimationVersion })
      ] === 'Finished'
        ? `/v1/estimation-calculation/estimation/${estimationId}/version/${estimationVersion}/download-report-xlsx?show-subtotal=${withSubtotals}`
        : ''
    },
  })
}

export function useEstimation({
  estimationService,
  withSubtotals,
}: {
  readonly estimationService: EstimationService
  withSubtotals?: boolean
}): UseEstimationValue {
  const currentEstimation = useStore(
    estimationService.estimation.currentEstimation$,
  )
  const allCurrentCalculations = useStoreMap({
    store: estimationService.estimationCalculation.calculation$,
    keys: [currentEstimation],
    fn: (calculations, [currCh]) =>
      currCh?.calculations.map(id => calculations[id]) || null,
  })

  const estimation = React.useMemo(
    (): UseEstimationValue['estimation'] =>
      currentEstimation
        ? {
            estimationId: currentEstimation.id,
            estimationVersion: currentEstimation.version,
          }
        : null,
    [currentEstimation],
  )

  const estimationKey = estimation
    ? buildEstimationStatusKey({
        estimationId: estimation.estimationId,
        estimationVersion: estimation.estimationVersion,
      }) || null
    : null

  const xlsxReportUrl = useXlsReport({
    estimationId: estimation?.estimationId,
    estimationVersion: estimation?.estimationVersion,
    estimationService,
    withSubtotals: !!withSubtotals,
  })

  const estimationStatus = useEstimationCalculationStatus({
    estimationKey,
    estimationService,
  })

  const pendingRequest = useStore(api.Estimation.start.defaultContext.pending$)

  const pendingStatus =
    estimationStatus === 'InProgress' || estimationStatus === 'Started'

  const pending = pendingRequest || pendingStatus

  const emptyCondition = React.useMemo(
    () =>
      !allCurrentCalculations?.some(
        c =>
          c &&
          (c.complexFieldsProperties.length > 0 ||
            !c.complexFields.some(cField => !cField.formula) ||
            c.simpleFieldsProperties.length > 0),
      ),
    [allCurrentCalculations],
  )

  const error = !!(estimationStatus && estimationStatus === 'Error')

  return React.useMemo(
    () => ({
      xlsxReportUrl,
      estimationStatus,
      pending,
      estimation,
      estimationKey,
      error,
      emptyCondition,
    }),
    [
      xlsxReportUrl,
      estimationStatus,
      pending,
      estimation,
      estimationKey,
      error,
      emptyCondition,
    ],
  )
}
