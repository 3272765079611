import { WarningCycle } from '@gmini/ui-kit'
import React from 'react'

import {
  Backdrop,
  ButtonWrapper,
  Card,
  MainWrapper,
  LeftInfoWrapper,
  TextWrapper,
  IconWrapper,
} from './InfoContainer.styled'

export const InfoContainer = ({
  text,
  actionButton,
  backDrop,
  background,
  borderColor,
}: {
  text: React.ReactNode
  actionButton?: React.ReactNode
  backDrop?: React.ReactNode
  background?: string
  borderColor?: string
}) => (
  <div
    style={{
      width: '100%',
    }}
  >
    <Card background={background} borderColor={borderColor}>
      <MainWrapper>
        <LeftInfoWrapper>
          <IconWrapper>
            <WarningCycle />
          </IconWrapper>
          <TextWrapper>{text}</TextWrapper>
        </LeftInfoWrapper>

        {actionButton && <ButtonWrapper>{actionButton}</ButtonWrapper>}
      </MainWrapper>
    </Card>
    {backDrop && <Backdrop />}
  </div>
)
