import { Code, Row, RowRightSide, RowLeftSide } from './HelpDialog.styled'

export const OperationTabContent = () => (
  <>
    <Row>
      <RowLeftSide>
        <Code>*</Code>
      </RowLeftSide>

      <RowRightSide>Умножение</RowRightSide>
    </Row>
    <Row>
      <RowLeftSide>
        <Code>/</Code>
      </RowLeftSide>
      <RowRightSide>Деление</RowRightSide>
    </Row>
    <Row>
      <RowLeftSide>
        <Code>+</Code>
      </RowLeftSide>
      <RowRightSide>
        <RowRightSide>Сложение</RowRightSide>
      </RowRightSide>
    </Row>
    <Row>
      <RowLeftSide>
        <Code>—</Code>
      </RowLeftSide>
      <RowRightSide>Вычитание</RowRightSide>
    </Row>
    <Row>
      <RowLeftSide>
        <Code>(...)</Code>
      </RowLeftSide>
      <RowRightSide>Скобки для обозначения приоритета</RowRightSide>
    </Row>
  </>
)
