import styled from 'styled-components'
import { Popover as PopoverInit } from '@mui/material'

export const Container = styled.div`
  display: flex;
  background-color: #f4f4f8;
  border-radius: 4px;
`

export const Content = styled.div`
  padding: 14px 8px 14px 14px;
  width: 100%;
`

export const FirstLine = styled.div`
  display: flex;
  margin-bottom: 14px;
`

export const CloseButtonWrap = styled.div`
  margin: 18px 8px 0 0;
`

export const WarningContainer = styled.div`
  padding: 24px 24px 20px 24px;
`

export const WarningActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 12px;
`

export const WarningDeleteText = styled.div`
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: rgb(53, 59, 96);
`

export const Popover = styled(PopoverInit)`
  & .MuiPaper-root {
    box-shadow: 0px 9px 30px rgba(53, 59, 96, 0.3);
    border-radius: 4px;
  }
`
