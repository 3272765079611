import { combine, merge, Store } from 'effector'
import { useStore, useStoreMap } from 'effector-react'

import { getNode, Nodes } from '@gmini/common/lib/classifier-service'

import {
  isDynamicBimElementReferenceNode,
  isReferenceNode,
} from '@gmini/common/lib/classifier-service/Node'

import { findInFlatTree } from '@gmini/common/lib/classifier-editor/ClassifierTree/utils'

import { isApiFlatNode } from '@gmini/common/lib/classifier-editor/ClassifierTree/createTree'

import {
  currentGroup$,
  setCurrentGroup,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/model/groupModel'

import * as smApi from '@gmini/sm-api-sdk'

import {
  apiToNodeTypeMap,
  isGroupType,
} from '@gmini/common/lib/classifier-service/adapters'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { classifierService } from '../../services/classifierService'

import { currentEstimationId$ } from '../CurrentEstimation'
import { estimationService } from '../../services/estimationService'
import { treeModel } from '../EstimationEditor/model/current/editorTreeModel'
import { getSourceGroupId } from '../EstimationEditor/model/group-tree'

import { isEstimationTreeNode } from './../EstimationEditor/common/types'

merge([
  smApi.UserClassifier.removeAll.done.map(({ params: { items } }) =>
    items.filter(({ type }) => isGroupType(apiToNodeTypeMap[type])),
  ),
  smApi.UserClassifierGroup.remove.doneData.map(group => [group]),
]).watch(removedGroups => {
  if (
    removedGroups.some(({ id }) => id === selectedGroupNode$.getState()?.id)
  ) {
    setCurrentGroup(null)
  }
})

export const selectedGroupNode$ = combine(
  {
    nodes: classifierService.nodes$,
    currentGroup: currentGroup$,
    flatTree: treeModel.flatTree$,
  },
  ({ flatTree, currentGroup, nodes }) => {
    if (!currentGroup) {
      return null
    }
    const flatNode = findInFlatTree(flatTree, currentGroup.path)
    if (flatNode && isApiFlatNode(flatNode)) {
      const node = getNode(nodes, flatNode.ref)
      return node && isEstimationTreeNode(node) ? node : null
    }

    return null
  },
)

export const currentCalculationId$ = combine(
  {
    calculations: estimationService.estimationCalculation.calculation$,
    currentEstimationId: currentEstimationId$,
    selectedGroupNode: selectedGroupNode$,
    nodes: classifierService.nodes$,
  },
  ({ calculations, currentEstimationId, selectedGroupNode, nodes }) => {
    if (!selectedGroupNode) {
      return null
    }

    const userClassifierGroupId = getSourceGroupId(selectedGroupNode)

    for (const key in calculations) {
      if (!Object.prototype.hasOwnProperty.call(calculations, key)) {
        continue
      }

      const rule = calculations[key]

      if (
        rule?.parentEstimationId === currentEstimationId &&
        rule?.groupId === userClassifierGroupId
      ) {
        return rule.id
      }
    }

    return null
  },
)

//TODO refactoring rule/calculation entity service
// selectedGroupNode$.watch(group => {
//   const ruleExists = Object.values(
//     estimationService.estimationCalculation.calculation$.getState(),
//   ).some(calculation => calculation?.groupId === group?.id)

//   const currentEstimation = estimationService.estimation.currentEstimation$.getState()
//   if (group && currentEstimation && !ruleExists) {
//     api.EstimationCalculation.create.defaultContext.submit({
//       estimationId: currentEstimation.id,
//       estimationVersion: currentEstimation.version,
//       groupId: group.id,
//     })
//   }
// })

export const useGroupRule = (
  groupId: null | number,
): null | api.EstimationCalculation =>
  useStoreMap({
    store: estimationService.estimationCalculation.calculation$,
    keys: [groupId],
    fn: (calculation, [id]) => {
      for (const key in calculation) {
        if (!Object.prototype.hasOwnProperty.call(calculation, key)) {
          continue
        }
        const rule = calculation[key]
        if (rule?.groupId === groupId) {
          return rule
        }
      }

      return null
    },
  })

export const currentCalculation$ = combine(
  {
    id: currentCalculationId$,
    calculationMap: estimationService.estimationCalculation.calculation$,
  },
  ({ id, calculationMap }) => calculationMap[id || 0] || null,
)

export function useSelectedGroupNode({ nodes$ }: { nodes$: Store<Nodes> }) {
  let selectedGroup = useStore(selectedGroupNode$)
  const nodes = useStore(nodes$)

  if (!selectedGroup) {
    return selectedGroup
  }

  if (
    isDynamicBimElementReferenceNode(selectedGroup) ||
    isReferenceNode(selectedGroup)
  ) {
    selectedGroup = getNode(nodes, selectedGroup.element)
  }

  return selectedGroup
}
