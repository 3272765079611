import styled from 'styled-components'

export const Code = styled.div`
  width: max-content;
  padding: 10px 15px;
  background: rgba(53, 59, 96, 0.05);
  border-radius: 4px;
  color: #353b60;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  min-width: 61px;
  text-align: center;
`

type SimpleCode = {
  padding?: string
}

export const SimpleCode = styled.div`
  display: inline-block;
  padding: ${(props: SimpleCode) =>
    props.padding ? props.padding : '9px 12px'};
  min-width: 63px;
  background: rgba(53, 59, 96, 0.05);
  border-radius: 5px;
  text-align: center;
  font-weight: 700;
  color: #353b60;

  &:not(:last-child) {
    margin-right: 4px;
  }

  & + & {
    margin-left: 4px;
  }
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`

export const CloseIcon = styled.div`
  cursor: pointer;
  width: 14px;
  height: 14px;
  margin-top: auto;
  margin-bottom: auto;

  & path {
    width: 100%;
    height: 100%;
  }
`

export const Container = styled.div`
  padding: 20px 24px 6px 24px;
  position: relative;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 8px 0;

  & + & {
    border-top: 1px solid #e2e4e9;
  }
`
type RowLeftSideProps = {
  flex?: string
}

export const RowLeftSide = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  flex: ${(props: RowLeftSideProps) => (props.flex ? props.flex : 1)};
  color: #000335;
  font-size: 12px;
`

type RowRightSide = {
  flex?: string
}

export const RowRightSide = styled.div`
  flex: ${(props: RowRightSide) => (props.flex ? props.flex : 10)};
  font-size: 12px;
  color: #000335;
`

export const DetailedHelpLink = styled.a`
  position: absolute;
  top: 58px;
  right: 24px;
  color: #4c5ecf;
  cursor: pointer;
  letter-spacing: 0.15px;
  font-size: 12px;
  font-weight: 700;
  line-height: 30px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const Alert = styled.div`
  display: flex;
  background: rgba(76, 94, 207, 0.1);
  border-radius: 4px;
  padding: 15px 24px 15px 15px;
  margin-top: 8px;
`

export const WarningIcon = styled.div`
  display: inline-block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;

  & path {
    width: 100%;
    height: 100%;
  }
`

export const AlertContent = styled.div`
  margin-left: 5px;
`

export const AlertText = styled.div`
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.1px;
  color: #616184;
  font-weight: 400;

  & + & {
    margin-top: 15px;
  }
`
