import React, { useEffect } from 'react'
import { ClickAwayListener } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'

import {
  BimCategoryNode,
  BimElementNode,
  BimFamilyNode,
  BimModelNode,
  BimModelReferenceNode,
  BimStandardSizeNode,
  DynamicBaseGroupNode,
  DynamicBimElementReferenceNode,
  DynamicGeneratedGroupNode,
  UserClassifierGroupNode,
} from '@gmini/common/lib/classifier-service/Node'

import { goals } from '@gmini/common/lib/metrika'
import { useReadonlyMode } from '@gmini/common'

import { CircularProgress } from '@gmini/ui-kit/lib/CircularProgress'

import {
  PlusCircle,
  Button,
  IconButton,
  HelpRounded,
  Tooltip,
} from '@gmini/ui-kit'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import {
  saveComplexFields,
  saveComplexFieldsPending$,
  unsavedConditionsByRule$,
  addCondition,
  ComplexFields,
} from '../conditions/complexFields'

import { useElementPropertyList } from '../properties/useElementPropertyList'

import { useCurrentCalculation } from '../../CurrentCalculation/useCurrentCalculation'

import { estimationService } from '../../../services/estimationService'

import { useEstimation } from '../../CurrentEstimation'

import { seoEventManager } from '../../../config'

import { ConditionItem } from './ConditionItem'
import {
  ConditionsContainer,
  AddButtonWrapper,
  Header,
  HeaderTitle,
} from './DifficultConditions.styled'

import { HelpDialog } from './HelpDialog'

const emptyConditions: ComplexFields[] = []

export type DifficultConditions = {
  currentGroup:
    | UserClassifierGroupNode
    | DynamicBaseGroupNode
    | DynamicGeneratedGroupNode
    | BimElementNode
    | DynamicBimElementReferenceNode
    | BimFamilyNode
    | BimCategoryNode
    | BimStandardSizeNode
    | BimModelNode
    | BimModelReferenceNode
  widthFormulaResizableCol: number
}

export const DifficultConditions = React.memo<DifficultConditions>(
  ({ currentGroup, widthFormulaResizableCol }) => {
    const currentCalculation = useCurrentCalculation()
    const currentEstimation = useStore(
      estimationService.estimation.currentEstimation$,
    )!
    const calculationId = currentCalculation?.id
    const savePending = useStore(saveComplexFieldsPending$)

    const { pending: estimationPending } = useEstimation({
      estimationService,
    })

    const conditions = useStoreMap({
      store: unsavedConditionsByRule$,
      keys: [calculationId],
      fn: (calculations, [calculationId]) =>
        calculationId && calculations[calculationId]
          ? calculations[calculationId]
          : emptyConditions,
    })

    useEffect(
      () => () => {
        if (calculationId) {
          saveComplexFields({ calculationId })
        }
      },
      [calculationId],
    )

    const complexConditions =
      currentCalculation?.complexFields || emptyConditions

    const { propertyElementList } = useElementPropertyList()

    const [helpOpen, setHelpOpen] = React.useState(false)

    const onAddCondition = (toBeginning?: boolean) => {
      goals.addEstimationFormula()

      if (!currentCalculation) {
        api.EstimationCalculation.create.defaultContext({
          estimationId: currentEstimation.id,
          estimationVersion: currentEstimation.version,
          groupId: currentGroup.id,
          complexFields: [
            ...complexConditions,
            {
              name: 'Количество',
              formula: '',
              unit: '',
            },
          ],
          simpleFields: [],
        })
      } else {
        if (calculationId) {
          addCondition({
            calculationId,
            newCondition: {
              name: 'Количество',
              formula: '',
              initialFormula: '',
              unit: '',
            },
            toBeginning,
          })

          seoEventManager.push({
            event: 'Gtech_Estimation_EstimationConditionSet',
            payload: {
              estimationId: currentEstimation.id,
              conditionType: 'complexCondition',
            },
          })
        }
      }
    }

    const onClickAway = () => {
      if (calculationId && conditions) {
        saveComplexFields({ calculationId })
      }
    }

    const onValidate = React.useCallback(async (formula: string) => {
      const res =
        await api.EstimationCalculation.validateFormula.defaultContext({
          formula,
        })
      return res.isValid
    }, [])

    const onClickHelp = React.useCallback(() => {
      setHelpOpen(true)
      goals.showEstimationFormulaHelp()
    }, [])

    const { readonlyMode } = useReadonlyMode()

    const pending = savePending || estimationPending

    return (
      <ClickAwayListener onClickAway={onClickAway} touchEvent='onTouchStart'>
        <div>
          <Header>
            <HeaderTitle>Вычислимые колонки</HeaderTitle>
            <Tooltip placement='top' title='Справка по формулам'>
              <IconButton
                size='small'
                type='circle'
                onClick={onClickHelp}
                data-test-id='columnHelp'
              >
                <HelpRounded
                  color='rgba(53, 59, 96, 0.25)'
                  width='20px'
                  height='20px'
                />
              </IconButton>
            </Tooltip>

            <HelpDialog open={helpOpen} onClose={() => setHelpOpen(false)} />
            {savePending && <CircularProgress size={15} />}
            <AddButtonWrapper>
              <Tooltip placement='top' title='Добавить вычислимую колонку'>
                <IconButton
                  data-test-id='addCalculationColumn'
                  onClick={() => onAddCondition(true)}
                  disabled={readonlyMode.enabled || pending}
                >
                  <PlusCircle width='24px' height='24px' />
                </IconButton>
              </Tooltip>
            </AddButtonWrapper>
          </Header>

          <ConditionsContainer>
            {conditions?.map((condition, idx) => (
              <ConditionItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${calculationId}__${idx}`}
                allSuggestions={propertyElementList}
                formula={condition.initialFormula}
                suggestionsInFormula={
                  currentCalculation?.complexFieldsProperties
                }
                name={condition.name}
                unit={condition.unit}
                onValidate={onValidate}
                disabled={readonlyMode.enabled || pending}
                widthFormulaResizableCol={widthFormulaResizableCol}
                idx={idx}
                calculationId={calculationId}
                errorName={condition.errorName}
                estimationId={currentEstimation.id}
              />
            ))}
          </ConditionsContainer>

          {Number(conditions?.length) > 0 && (
            <Button
              data-test-id='addCondition'
              disabled={readonlyMode.enabled || pending}
              onClick={() => onAddCondition()}
              color='secondary'
              leftIcon={<PlusCircle width='24px' height='24px' />}
              size='regular'
            >
              Добавить
            </Button>
          )}
        </div>
      </ClickAwayListener>
    )
  },
)

DifficultConditions.displayName = 'DifficultConditions'
