import React from 'react'
import { createGate } from 'effector-react'
import { Redirect, useParams } from 'react-router-dom'
export interface CurrentEstimationProps {
  id: number
}

const CurrentEstimationGate = createGate<CurrentEstimationProps | null>()

export const currentEstimationId$ = CurrentEstimationGate.state.map(
  props => props?.id || null,
)

export function useCurrentEstimationParams() {
  const { id } = useParams<{
    readonly id?: string
  }>()

  return React.useMemo(() => (id ? ({ id: +id } as const) : null), [id])
}

export const CurrentEstimation = React.memo(() => {
  const params = useCurrentEstimationParams()

  if (!params) {
    return <Redirect to='/' />
  }
  return <CurrentEstimationGate id={params.id} />
})

CurrentEstimation.displayName = 'CurrentEstimation'
