import { useStore, useStoreMap } from 'effector-react'

import { equals } from 'ramda'

import { isNumber } from '@gmini/utils'

import * as smApi from '@gmini/sm-api-sdk'

import { buildClsGroupKey } from '@gmini/common/lib/Services/EstimationService'

import { estimationService } from '../../../services/estimationService'

import { currentUserClassifierProps$ } from '../../CurrentUserClassifier'
import { useSelectedGroupNode } from '../../CurrentCalculation'
import { classifierService } from '../../../services/classifierService'

export function useElementPropertyList() {
  const currentClassifier = useStore(currentUserClassifierProps$)
  const pending = useStore(
    smApi.UserClassifierGroup.Property.List.fetchElementProperties
      .defaultContext.pending$,
  )

  const selectedGroup = useSelectedGroupNode({
    nodes$: classifierService.nodes$,
  })

  const propertyElementList = useStoreMap({
    store: estimationService.elementProps.byKey$,
    keys: [
      currentClassifier?.id,
      currentClassifier?.version,
      selectedGroup?.id,
    ],
    fn: (byElementCalc, [classifierId, classifierVersion, groupId]) =>
      isNumber(classifierId) && isNumber(classifierVersion) && isNumber(groupId)
        ? byElementCalc?.[
            buildClsGroupKey({
              classifierId,
              groupId,
            })
          ] || []
        : [],
    updateFilter: (
      newResult: smApi.UserClassifierGroup.Property[],
      oldResult: smApi.UserClassifierGroup.Property[],
    ) => !equals(oldResult, newResult),
  })

  return {
    propertyElementList,
    pending,
  }
}
