import { Nodes } from '@gmini/common/lib/classifier-service'
import {
  BimCategoryNode,
  BimElementNode,
  BimFamilyNode,
  BimModelNode,
  BimStandardSizeNode,
  DynamicBaseGroupNode,
  DynamicGeneratedGroupNode,
  isDynamicBaseGroupNode,
  isDynamicGeneratedGroupNode,
  isUserClassifierGroupNode,
  UserClassifierGroupNode,
} from '@gmini/common/lib/classifier-service/Node'
import {
  Tooltip,
  FolderDynamicGenerated,
  Cube,
  Category,
  Family,
  Home,
  Cubes,
  FolderDynamicGeneratedChild,
  FamilyBlue,
  CategoryBlue,
  CubesBlue,
  VirtualStandardSize,
} from '@gmini/ui-kit'
import { useStore } from 'effector-react'

import { Store } from 'effector'

import { ReactNode } from 'react'

import { dynamicGroupMode$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/dynamicGroupMode'

import {
  useChildrenDynamicHasGrouping,
  useDisabledDynamicGroup,
  useParentUserClassifierNodeByDynamicNode,
} from '../EstimationEditor/model/group-tree'

import { truncateText } from '../EstimationEditor/truncateText'

import { useClassifierDynamicConditions } from '../EstimationEditor/model/dynamic-conditions.store'

import { NoAvailableActionMessage } from './NoAvailableActionMessage'
import { GroupName, FolderName } from './NoAvailableActionMessage.styled'

export const useNoAvailableMessage = (
  selectedNode:
    | UserClassifierGroupNode
    | DynamicBaseGroupNode
    | DynamicGeneratedGroupNode
    | BimElementNode
    | BimFamilyNode
    | BimCategoryNode
    | BimStandardSizeNode
    | BimModelNode
    | null,
  nodes$: Store<Nodes>,
  classifierId: number,
) => {
  const nodes = useStore(nodes$)
  const dynamicGroupMode = useStore(dynamicGroupMode$)
  const childrenDynamicHasGrouping = useChildrenDynamicHasGrouping({
    classifierId,
  })

  const getParentGroupByDynamicNode = useParentUserClassifierNodeByDynamicNode({
    nodes$,
  })
  const dynamicGroupConditions = useClassifierDynamicConditions({
    classifierId,
  })

  const branchHasGroupingFunc = useDisabledDynamicGroup({
    dynamicGroupConditions,
    nodes$,
    maxGroupingCount: 0,
  })

  if (
    !selectedNode ||
    isUserClassifierGroupNode(selectedNode) ||
    (isDynamicGeneratedGroupNode(selectedNode) &&
      selectedNode?.source?.type === 'UserClassifierGroup')
  ) {
    return null
  }

  let groupType = ''
  let icon: ReactNode = null
  let helpText = ''
  let parentUserClassifierGroup: UserClassifierGroupNode | null = null

  if (
    isDynamicGeneratedGroupNode(selectedNode) ||
    isDynamicBaseGroupNode(selectedNode)
  ) {
    parentUserClassifierGroup = getParentGroupByDynamicNode(selectedNode)
  }

  let branchHasGrouping = parentUserClassifierGroup
    ? branchHasGroupingFunc(parentUserClassifierGroup)
    : false

  if (!branchHasGrouping && parentUserClassifierGroup) {
    branchHasGrouping = dynamicGroupConditions.some(
      ({ sourceGroupId }) => parentUserClassifierGroup?.id === sourceGroupId,
    )
  }

  switch (selectedNode.type) {
    case 'DynamicGeneratedGroupNode': {
      const hasChildWithGrouping = childrenDynamicHasGrouping(
        selectedNode,
        nodes,
      )

      groupType = 'Динамическая папка'

      if (hasChildWithGrouping) {
        icon = <FolderDynamicGeneratedChild width='96px' height='96px' />
      } else if (
        !branchHasGrouping &&
        dynamicGroupMode &&
        selectedNode.source?.type === 'BimStandardSize'
      ) {
        icon = <VirtualStandardSize width='96px' height='96px' />
      } else if (selectedNode.source?.type === 'BimStandardSize') {
        icon = <CubesBlue width='96px' height='96px' />
      } else if (selectedNode.source?.type === 'BimCategory') {
        icon = <CategoryBlue width='96px' height='96px' />
      } else if (selectedNode.source?.type === 'BimFamily') {
        icon = <FamilyBlue width='96px' height='96px' />
      } else {
        icon = <FolderDynamicGenerated width='96px' height='96px' />
      }

      helpText =
        'Данная папка создана путем применения группировки на одну из родительских папок и к ней нельзя применить какие либо условия или дополнительные группировки.'

      break
    }
    case 'BimElementNode': {
      groupType = 'Элемент'
      icon = <Cube width='96px' height='96px' />
      helpText =
        'Нельзя накладывать “Условия” и “Группировки” на такие сущности как: модель, категория, семейство, типоразмер и элемент.'
      break
    }
    case 'BimFamilyNode': {
      groupType = 'Семейство'
      icon = <Family width='96px' height='96px' />
      helpText =
        'Нельзя накладывать “Условия” и “Группировки” на такие сущности как: модель, категория, семейство, типоразмер и элемент.'
      break
    }
    case 'BimCategoryNode': {
      groupType = 'Категория'
      icon = <Category width='96px' height='96px' />
      helpText =
        'Нельзя накладывать “Условия” и “Группировки” на такие сущности как: модель, категория, семейство, типоразмер и элемент.'
      break
    }
    case 'BimStandardSizeNode': {
      groupType = 'Типоразмер'
      icon = <Cubes width='96px' height='96px' />
      helpText =
        'Нельзя накладывать “Условия” и “Группировки” на такие сущности как: модель, категория, семейство, типоразмер и элемент.'
      break
    }
    case 'BimModelNode': {
      groupType = 'Модель'
      icon = <Home width='96px' height='96px' />
      helpText =
        'Нельзя накладывать “Условия” и “Группировки” на такие сущности как: модель, категория, семейство, типоразмер и элемент.'
      break
    }

    default:
      throw new Error(`Unexpected type ${selectedNode.type} `)
  }

  return (
    <NoAvailableActionMessage
      folderName={
        <FolderName>
          <GroupName>{groupType}:</GroupName>
          <Tooltip title={selectedNode?.name}>
            <>{truncateText(selectedNode.name, 20)}</>
          </Tooltip>
        </FolderName>
      }
      icon={icon}
      text={helpText}
    />
  )

  return null
}
