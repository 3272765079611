import { createEffect, createStore, sample } from 'effector'

import { broadcast, isNotEmpty } from '@gmini/utils'

import { createClassifierService } from '@gmini/common/lib/classifier-service'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { connectSetManagementAndModelRepo } from './classifierServiceConnect'
import { notificationService } from './notificationService'
import { messageMap } from './messageMap'

export const estimationVersionReq =
  api.Estimation.getMostRecentSilent.createContext('estimationVersionReq')

export const estimationVersion = createEffect({
  handler: async ({ id, version }: { id: number; version: number }) => {
    const actual = await estimationVersionReq({ id })
    const active = actual.version < version ? false : actual.version !== version
    return { active }
  },
})

export const oldVersionMode$ = createStore<{ active: boolean } | null>(null)
  .on(estimationVersion.doneData, (_, value) => value)
  .on(estimationVersion.failData, () => ({ active: false }))
  .on(smApi.UserClassifier.getMostRecent.done, () => null)
  .on(api.Estimation.getMostRecent.done, () => null)

export const classifierService = createClassifierService({
  name: 'classifierService',
  apiErrMessageMap: messageMap,
})

export const selectDependenciesClassifierService = createClassifierService({
  name: 'selectDependenciesClassifierService',
  apiErrMessageMap: messageMap,
})

const message = sample({
  source: oldVersionMode$,
  clock: notificationService.message,
  fn: (oldVersionMode, m) => {
    if (oldVersionMode && oldVersionMode.active) {
      return oldVersionMessMap.includes(m.type) ? m : null
    }
    return m
  },
}).filter({ fn: isNotEmpty })

broadcast(message.filter({ fn: smApi.NotificationEvent.is }), [
  classifierService.notification,
  selectDependenciesClassifierService.notification,
])

connectSetManagementAndModelRepo()

const oldVersionMessMap = [
  'EstimationChangeStatusEvent',
  'SubscribeEvent',
  'UnsubscribeEvent',
]
