import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

/**
 * Delete rules one by one
 * @param calculations list of rules to delete
 * @param param1 checkup props
 */
export async function removeAllRules(
  calculations: api.EstimationCalculation[],
  {
    estimationId,
    estimationVersion,
  }: { estimationId: number; estimationVersion: number },
) {
  const leftCalculations = [...calculations]
  let lastCheckupVersion = estimationVersion

  while (leftCalculations.length > 0) {
    const calculation = leftCalculations.shift()!
    const nextEstimation =
      // eslint-disable-next-line no-await-in-loop
      await api.EstimationCalculation.remove.defaultContext({
        estimationId,
        estimationVersion: lastCheckupVersion,
        calculationId: calculation.id,
      })
    lastCheckupVersion = nextEstimation.version
  }
}
