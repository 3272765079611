import styled from 'styled-components'

export const SelectWrapper = styled.div`
  padding-left: 14px;
`

export const RenderItemLayout = styled.div`
  display: flex;
  align-items: center;
`

export const RenderListItemLayout = styled(RenderItemLayout)`
  &:hover {
    background: rgba(0, 5, 100, 0.04);
  }
  padding-left: 12px;
`

export const RenderListItemText = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  flex-grow: 1;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  cursor: default;
`

type RenderItemTextProps = {
  selected: boolean
}

export const RenderItemText = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: ${({ selected }: RenderItemTextProps) =>
    selected ? 'rgba(34, 53, 170, 1)' : 'rgba(0, 0, 61, 1)'};
  cursor: pointer;
`

export const RenderSelectedItem = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: rgba(34, 53, 170, 1);
`

export const ArrowIconWrapper = styled.div`
  padding-left: 6px;
  padding-top: 5px;
  cursor: pointer;
`

export const PopoverContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(53, 60, 96, 0.15);
  border-radius: 4px;
`

export const SelectedIconWrapper = styled.div`
  width: 14px;
  margin-left: 28px;
  margin-right: 15px;
`
