import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
`

export const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
`

export const NotActualCard = styled.div`
  background: #ffedce;
  min-height: 68px;
  opacity: 1;
  border: 1px solid #f5a623;
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 11000;

  position: relative;
`

export const ButtonWrapper = styled.div`
  height: 48px;
`

export const NotActualWrapper = styled.div`
  margin: 3px 14px 28px 14px;
  min-width: 414px;
`

export const NotActualText = styled.div`
  font-family: Open-Sans, Helvetica, Sans-Serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`
