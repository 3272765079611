import { useStore } from 'effector-react'

import { ReportFlatNode } from '../model/treeModel'
import { withSubtotals$ } from '../Subtotals/model'

import { TableCell } from './CellContent.styled'
import { createColumnKey } from './createColumnKey'
import { RenderColumn } from './types'

export const CellContent = ({
  item,
  column,
}: {
  item: ReportFlatNode
  column: RenderColumn
}) => {
  const withSubtotals = useStore(withSubtotals$)

  const valueItem = item.node.values.find(cell =>
    column.key.some(
      key => key === createColumnKey({ id: cell.columnId, type: cell.type }),
    ),
  )

  const showSubtotals = withSubtotals && !!item.node.values.length

  const { isError, text } = getCellText({
    dimension: column.dimension,
    leaf: item.leaf,
    showSubtotals,
    value: valueItem?.value,
    columnType: column.type,
  })

  return <TableCell errorText={isError}>{text}</TableCell>
}

export function getCellText({
  value,
  dimension,
  leaf,
  showSubtotals,
  columnType,
}: {
  value: string | null | undefined
  leaf: boolean
  showSubtotals: boolean
  dimension?: string | null | undefined
  columnType: 'SimpleField' | 'ComplexField'
}): { isError: boolean; text: string } {
  const showComplexFieldSubtotals =
    showSubtotals && columnType === 'ComplexField'
  const isError = isErrorFunc(value)
  const text = normalizeIfNum(value || '')
  const cellText = isError ? 'Ошибка' : `${text}${' '}${dimension || ''}`
  const resultText = leaf || showComplexFieldSubtotals ? cellText : ''

  return { isError, text: resultText }
}

function isErrorFunc(text: string | null | undefined) {
  return text === null || text === 'Ошибка'
}

function normalizeIfNum(txt: string) {
  let text = txt
  if (text && !isNaN(+text)) {
    text = (+text).toFixed(2)
  }

  return text
}
