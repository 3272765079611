import { WarningOutlinedRotate } from '@gmini/ui-kit/lib/icons'
import { ReactNode } from 'react'

import {
  Container,
  Name,
  Wrapper,
  Icon,
  TextWrapper,
  TextMessage,
  WarningIconWrapper,
} from './NoAvailableActionMessage.styled'

type NoAvailableActionMessageProps = {
  text?: ReactNode
  icon?: ReactNode
  folderName?: ReactNode
}

export const NoAvailableActionMessage = ({
  text,
  icon,
  folderName,
}: NoAvailableActionMessageProps) => (
  <Wrapper>
    <Container>
      <Icon>{icon}</Icon>

      <Name>{folderName}</Name>
      <TextWrapper>
        <WarningIconWrapper>
          <WarningOutlinedRotate />
        </WarningIconWrapper>

        <TextMessage>{text}</TextMessage>
      </TextWrapper>
    </Container>
  </Wrapper>
)
