import { createEvent, createStore, sample } from 'effector'

import { clone, equals } from 'ramda'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { currentCalculation$ } from '../../CurrentCalculation'
import { InputNames } from '../DifficultConditions/types'

export type SaveComplexFieldsParams = {
  calculationId: number
}

export const saveComplexFields = createEvent<SaveComplexFieldsParams>()

export const saveComplexFieldsApi =
  api.EstimationCalculation.updateFields.createContext()

export const saveComplexFieldsPending$ = saveComplexFieldsApi.pending$

export type ComplexFields = {
  initialFormula: string
  errorName?: string
} & api.ComplexField

export const setInitialState = createEvent<ComplexFields[]>()

export const addCondition = createEvent<{
  newCondition: ComplexFields
  toBeginning?: boolean
  calculationId: number
}>()
export const deleteCondition = createEvent<{
  idxCondition: number
  calculationId: number
}>()
export const updateConditionField = createEvent<{
  idxCondition: number
  newFieldValue: string
  fieldName: InputNames
  calculationId: number
}>()

export type ConditionsByRuleID = Record<string, ComplexFields[] | null>

// Мапа для условий, которые еще не сохранены
export const unsavedConditionsByRule$ = createStore<ConditionsByRuleID>({})
  .on(currentCalculation$, (state, result) => {
    const next = clone(state)

    if (result?.id) {
      next[result.id] = result.complexFields.map(({ unit, formula, name }) => ({
        initialFormula: formula,
        unit,
        formula,
        name,
      }))
    }

    return next
  })
  .on(api.EstimationCalculation.remove.done, (state, { params }) => {
    if (state[params.calculationId]) {
      const next = clone(state)
      delete next[params.calculationId]

      return next
    }
    return state
  })
  .on(addCondition, (state, { calculationId, newCondition, toBeginning }) => {
    const next = { ...state }

    next[calculationId] = toBeginning
      ? [newCondition, ...(next[calculationId] || [])]
      : [...(next[calculationId] || []), newCondition]

    return next
  })
  .on(deleteCondition, (state, { calculationId, idxCondition }) => {
    const next = { ...state }

    next[calculationId] =
      next[calculationId]?.filter((_, idx) => idx !== idxCondition) || []

    return next
  })
  .on(
    updateConditionField,
    (state, { calculationId, idxCondition, fieldName, newFieldValue }) => {
      const next = clone(state)
      const complexConditions = state[calculationId] || []
      let errorName = ''
      if (
        fieldName === 'name' &&
        next[calculationId]?.some(({ name }) => name === newFieldValue)
      ) {
        errorName = 'Нельзя сохранять колонки с одинаковыми именами'
      }

      return {
        ...state,
        [calculationId]: [
          ...complexConditions.slice(0, idxCondition),
          {
            ...complexConditions[idxCondition],
            [fieldName]: newFieldValue,
            errorName,
          },
          ...complexConditions.slice(idxCondition + 1),
        ],
      }
    },
  )

export const conditionChanged$ = sample({
  clock: unsavedConditionsByRule$,
  source: currentCalculation$,
  fn: (currentRule, unsavedConditionsByRule) =>
    currentRule
      ? !equals(
          currentRule?.complexFields.map(({ name, formula, unit }) => ({
            name,
            formula,
            unit,
          })),
          unsavedConditionsByRule[currentRule.id]?.map(
            ({ formula, name, unit }) => ({
              formula,
              name,
              unit,
            }),
          ),
        )
      : false,
})
