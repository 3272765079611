import { useStore } from 'effector-react'
import { combine, Store } from 'effector'
import { equals } from 'ramda'

import { node } from '@gmini/common/lib/classifier-service'

import { classifierService } from '../../services/classifierService'
import { estimationService } from '../../services/estimationService'

export interface UserClassifierProps {
  readonly id: number
  readonly version: number
}

const classifierId$ = estimationService.estimation.currentEstimation$.map(
  ch => ch?.classifierId || null,
)

export const currentUserClassifier$: Store<null | node.UserClassifierNode> =
  combine(
    classifierService.userClassifier.nodes$,
    classifierId$,
    (nodes, id) => {
      if (!id) {
        return null
      }

      const userClassifierNode = nodes[id]
      if (!userClassifierNode) {
        return null
      }

      return userClassifierNode
    },
  )

export const projectUrn$ = currentUserClassifier$.map(classifier =>
  classifier ? classifier.projectUrn : null,
)

export type CurrentUserClassifierProps = {
  id: number
  version: number
}

export const currentUserClassifierProps$: Store<CurrentUserClassifierProps | null> =
  currentUserClassifier$
    .map(classifier =>
      classifier ? { id: classifier.id, version: classifier.version } : null,
    )
    .map((next, prev) =>
      prev === null || !equals(next, prev)
        ? next
        : (prev as CurrentUserClassifierProps),
    )

export function useCurrentUserClassifier(): null | node.UserClassifierNode {
  return useStore(currentUserClassifier$)
}
