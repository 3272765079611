import { createStore, Store } from 'effector'

import { useStore } from 'effector-react'

import { isNotEmpty } from '@gmini/utils'

import * as smApi from '@gmini/sm-api-sdk'

import {
  DynamicBaseGroupNode,
  DynamicGeneratedGroupNode,
  isDynamicBaseGroupNode,
  isDynamicGeneratedGroupNode,
  isUserClassifierGroupNode,
  isUserClassifierGroupWithGroupsNode,
  UserClassifierGroupNode,
} from '@gmini/common/lib/classifier-service/Node'

import {
  getChildren as getChildrenFromNodes,
  Nodes,
} from '@gmini/common/lib/classifier-service'

import { useMemo } from 'react'

import { estimationService } from '../../../services/estimationService'

import { EstimationTreeNode } from './../common/types'

import {
  UseDisabledDynamicGroupParams,
  UsePropertyFilteredListParams,
} from './group-tree.types'
import { useClassifierDynamicConditions } from './dynamic-conditions.store'

export const groupTree$ = createStore<smApi.UserClassifierTree.GroupItem[]>(
  [],
).on(
  smApi.UserClassifierTree.GroupItem.getList.doneData,
  (_, { groupTree }) => groupTree,
)

const ruleGroupIds$ = estimationService.estimationCalculation.calculation$.map(
  m =>
    Object.values(m)
      .filter(isNotEmpty)
      .map(({ groupId }) => groupId),
)

export const useDisabledGroup = () => {
  const tree = useStore(groupTree$)
  const ruleGroupIds = useStore(ruleGroupIds$)

  return (id: number) => {
    const parents = getParents(tree, id)
    const children = getChildren(tree, id)

    return (
      parents.some(item =>
        ruleGroupIds.some(ruleGroupId => item.id === ruleGroupId),
      ) ||
      children.some(item =>
        ruleGroupIds.some(ruleGroupId => item.id === ruleGroupId),
      )
    )
  }
}

export const useChildrenHasRule = () => {
  const tree = useStore(groupTree$)
  const ruleGroupIds = useStore(ruleGroupIds$)

  return (id: number) => {
    const children = getChildren(tree, id)

    return children.some(item =>
      ruleGroupIds.some(ruleGroupId => item.id === ruleGroupId),
    )
  }
}

export const useChildrenHasGrouping = ({
  classifierId,
}: {
  classifierId: number
}) => {
  const tree = useStore(groupTree$)
  const dynamicGroupConditions = useClassifierDynamicConditions({
    classifierId,
  })

  return (groupId: number) => {
    const children = getChildren(tree, groupId)

    const dynamicTarget = dynamicGroupConditions.find(
      gr => gr.sourceGroupId === groupId,
    )

    if (dynamicTarget) {
      return false
    }

    return children.some(item =>
      dynamicGroupConditions.some(gr => gr.sourceGroupId === item.id),
    )
  }
}

export const useChildrenDynamicHasGrouping = ({
  classifierId,
}: {
  classifierId: number
}) => {
  const dynamicGroupConditions = useClassifierDynamicConditions({
    classifierId,
  })

  return (
    node: DynamicGeneratedGroupNode | DynamicBaseGroupNode,
    nodes: Nodes,
  ) => {
    const children = getChildrenFromNodes(nodes, node)

    const result: (DynamicGeneratedGroupNode | DynamicBaseGroupNode)[] = []

    function getChildren(
      node: DynamicGeneratedGroupNode | DynamicBaseGroupNode,
    ) {
      if (isDynamicGeneratedGroupNode(node)) {
        result.push(node)
      }

      const children = getChildrenFromNodes(nodes, node)
      if (children.length) {
        children.forEach(node => {
          if (isDynamicGeneratedGroupNode(node)) {
            getChildren(node)
          }
        })
      }
    }

    children.forEach(node => {
      if (isDynamicGeneratedGroupNode(node)) {
        getChildren(node)
      }
    })

    return result.some(
      node =>
        isDynamicGeneratedGroupNode(node) &&
        node?.source?.type === 'UserClassifierGroup' &&
        dynamicGroupConditions.some(
          ({ sourceGroupId }) => sourceGroupId === node?.source?.id,
        ),
    )
  }
}

export function usePropertyFilteredList({
  group,
  dynamicGroupConditions,
  nodes$,
  propertyList,
  groupingConditions,
}: UsePropertyFilteredListParams) {
  const nodes = useStore(nodes$)

  const propertyFilteredList = useMemo(
    () =>
      propertyList.filter(prop =>
        groupingConditions?.every(
          ({ conditionElementPropertyId }) =>
            conditionElementPropertyId !== prop.id,
        ),
      ),
    [groupingConditions, propertyList],
  )

  if (!group) {
    return propertyFilteredList
  }

  const parentConditions = group.parentGroupId
    ? getConditions(findParentGroupNodes(group, nodes), dynamicGroupConditions)
    : []

  const childrenConditions = isUserClassifierGroupWithGroupsNode(group)
    ? getConditions(
        findAllGroupsInChildren(
          getChildrenFromNodes(nodes, group).filter(isUserClassifierGroupNode),
          nodes,
        ),
        dynamicGroupConditions,
      )
    : []

  const conditions = [...childrenConditions, ...parentConditions]

  return conditions.length
    ? propertyFilteredList.filter(
        prop =>
          !conditions.some(
            condition => condition.conditionElementPropertyId === prop.id,
          ),
      )
    : propertyFilteredList
}

function getConditions(
  groupNode: UserClassifierGroupNode[],
  dynamicGroupConditions: smApi.UserClassifierTree.DynamicGroupItem.DynamicGroupConditionItem[],
) {
  return groupNode
    .flatMap(
      group =>
        dynamicGroupConditions.find(
          ({ sourceGroupId }) => sourceGroupId === group.id,
        )?.groupingConditions,
    )
    .filter(isNotEmpty)
}

function findAllGroupsInChildren(
  children: UserClassifierGroupNode[],
  nodes: Nodes,
) {
  const result: UserClassifierGroupNode[] = []

  function getChildren(group: UserClassifierGroupNode) {
    result.push(group)

    const children = isUserClassifierGroupWithGroupsNode(group)
      ? getChildrenFromNodes(nodes, group).filter(isUserClassifierGroupNode)
      : []

    if (children.length) {
      children.forEach(getChildren)
    }
  }

  children.forEach(getChildren)

  return result
}

export const useParentUserClassifierNodeByDynamicNode = ({
  nodes$,
}: {
  nodes$: Store<Nodes>
}) => {
  const nodes = useStore(nodes$)

  return (
    node: DynamicGeneratedGroupNode | DynamicBaseGroupNode,
  ): UserClassifierGroupNode | null => {
    let parentUserClassifierGroup: UserClassifierGroupNode | null = null
    function recursive(node: DynamicGeneratedGroupNode | DynamicBaseGroupNode) {
      if (
        isDynamicGeneratedGroupNode(node) &&
        node.source?.type === 'UserClassifierGroup'
      ) {
        parentUserClassifierGroup =
          nodes.UserClassifierGroupNode[node.source?.id] || null

        return
      }

      if (isDynamicBaseGroupNode(node) && node.sourceGroupId) {
        parentUserClassifierGroup =
          nodes.UserClassifierGroupNode[node.sourceGroupId] || null

        return
      }

      if (node.parentDynamicGroupId) {
        const parentDynamicGroup =
          nodes.DynamicGeneratedGroupNode[node.parentDynamicGroupId] ||
          nodes.DynamicBaseGroupNode[node.parentDynamicGroupId]

        if (parentDynamicGroup) {
          recursive(parentDynamicGroup)
        }
      }

      return null
    }

    if (node.parentDynamicGroupId) {
      recursive(node)

      return parentUserClassifierGroup
    }

    return null
  }
}

export const useDisabledDynamicGroup = ({
  nodes$,
  maxGroupingCount,
  dynamicGroupConditions,
}: UseDisabledDynamicGroupParams) => {
  const nodes = useStore(nodes$)

  return (group: UserClassifierGroupNode | null) => {
    if (!group) {
      return false
    }

    const parents =
      isUserClassifierGroupNode(group) && group.parentGroupId
        ? findParentGroupNodes(group, nodes)
        : []

    const parentsGroupingCount = parents.reduce(
      (acc, next) =>
        dynamicGroupConditions.some(
          ({ sourceGroupId }) => sourceGroupId === next.id,
        )
          ? acc + 1
          : acc,
      0,
    )

    let groupingCount = parentsGroupingCount

    if (
      maxGroupingCount === 0 &&
      group.type === 'UserClassifierEmptyGroupNode'
    ) {
      return groupingCount > maxGroupingCount
    }

    if (group.type === 'UserClassifierEmptyGroupNode') {
      return groupingCount >= maxGroupingCount
    }

    const children = getChildrenFromNodes(nodes, group).filter(
      isUserClassifierGroupNode,
    )

    children.forEach(child => {
      if (
        (maxGroupingCount === 0 && groupingCount > maxGroupingCount) ||
        groupingCount >= maxGroupingCount
      ) {
        return
      }

      groupingCount = parentsGroupingCount

      findChildGrouping(child, nodes, dynamicGroupConditions, () => {
        groupingCount++
      })
    })

    if (maxGroupingCount === 0) {
      return groupingCount > maxGroupingCount
    }

    return groupingCount >= maxGroupingCount
  }
}

function findChildGrouping(
  group: UserClassifierGroupNode,
  nodes: Nodes,
  dynamicGroupConditions: smApi.UserClassifierTree.DynamicGroupItem.DynamicGroupConditionItem[],
  onDynamicGroupExists: () => void,
) {
  if (group.type === 'UserClassifierEmptyGroupNode') {
    return
  }

  const dynamicGroupStatus = (id: number) =>
    dynamicGroupConditions.some(({ sourceGroupId }) => id === sourceGroupId)

  const children = getChildrenFromNodes(nodes, group).filter(
    isUserClassifierGroupNode,
  )

  if (dynamicGroupStatus(group.id)) {
    onDynamicGroupExists()
  }

  if (children.length) {
    children.forEach(child =>
      findChildGrouping(
        child,
        nodes,
        dynamicGroupConditions,
        onDynamicGroupExists,
      ),
    )
  }
}

function findParentGroupNodes(
  currGroup: UserClassifierGroupNode,
  nodes: Nodes,
) {
  const parents: UserClassifierGroupNode[] = []

  function getParent(group: UserClassifierGroupNode) {
    const parentGroup = group.parentGroupId
      ? nodes.UserClassifierGroupNode[group.parentGroupId]
      : null

    if (!parentGroup) {
      return
    }

    parents.push(parentGroup)

    if (parentGroup.parentGroupId) {
      getParent(parentGroup)
    }
  }

  getParent(currGroup)

  return parents
}

//TODO сделать сервис по перезапросам GroupItem.getList
smApi.BimReference.moveRefs.doneData.watch(params => {
  smApi.UserClassifierTree.GroupItem.getList.defaultContext.submit({
    classifierVersion: params.version,
    classifierId: params.id,
  })
})

function getParents(
  tree: smApi.UserClassifierTree.GroupItem[],
  groupId: number,
): smApi.UserClassifierTree.GroupItem[] {
  const parents: smApi.UserClassifierTree.GroupItem[] = []
  const target = tree.find(item => item.id === groupId)

  function recursive(targ: smApi.UserClassifierTree.GroupItem) {
    const parent = tree.find(item => item.id === targ.parentGroupId)
    if (parent) {
      parents.push(parent)
      recursive(parent)
    }
  }

  if (target) {
    recursive(target)
  }

  return parents
}

function getChildren(
  tree: smApi.UserClassifierTree.GroupItem[],
  groupId: number,
): smApi.UserClassifierTree.GroupItem[] {
  const children: smApi.UserClassifierTree.GroupItem[] = []
  const target = tree.find(item => item.id === groupId)

  function recursive(targ: smApi.UserClassifierTree.GroupItem) {
    const _children = tree.filter(item => item.parentGroupId === targ.id)

    _children.forEach(ch => {
      children.push(ch)
      recursive(ch)
    })
  }

  if (target) {
    recursive(target)
  }

  return children
}

export function getUserClassifierGroupNode(
  group: EstimationTreeNode,
  nodes: Nodes,
) {
  if (group.type === 'DynamicBaseGroupNode') {
    return nodes.UserClassifierGroupNode[group.sourceGroupId] || null
  } else if (group.type === 'DynamicGeneratedGroupNode' && group.source?.id) {
    return nodes.UserClassifierGroupNode[group.source.id] || null
  }
  return null
}

export function getSourceGroupId(group: EstimationTreeNode) {
  if (group.type === 'DynamicBaseGroupNode') {
    return group.sourceGroupId
  } else if (group.type === 'DynamicGeneratedGroupNode' && group.source) {
    return group.source.id
  }
  return group.id
}
