import styled from 'styled-components'

export const LeftInfoWrapper = styled.div`
  display: flex;
`

export const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
`

type CardProps = {
  background?: string
  borderColor?: string
}
export const Card = styled.div`
  background: ${(props: CardProps) =>
    props.background ? props.background : 'rgba(255, 237, 206, 0.5)'};
  border: 1px solid;
  border-color: ${(props: CardProps) =>
    props.borderColor ? props.borderColor : 'rgba(255, 237, 206, 1)'};
  min-height: 68px;
  opacity: 1;
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 11000;
  position: relative;
`

export const ButtonWrapper = styled.div`
  height: 48px;
`

export const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 13px 0px 15px;
`

export const TextWrapper = styled.div`
  padding: 0 0 12px 8px;
`

export const IconWrapper = styled.div`
  min-width: 20px;
  min-height: 20px;
`

export const InfoContainerText = styled.div`
  font-family: Open-Sans, Helvetica, Sans-Serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(63, 63, 63, 1);
`
