import { getNode, Nodes } from '@gmini/common/lib/classifier-service'
import { apiToNodeTypeMap } from '@gmini/common/lib/classifier-service/adapters'
import {
  isDynamicBaseGroupNode,
  isDynamicGeneratedGroupNode,
  UserClassifierGroupNode,
  UserClassifierGroupWithElementsNode,
} from '@gmini/common/lib/classifier-service/Node'
import { SearchModel } from '@gmini/common'
import { CtxMenuBaseProps } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'

import { Store } from 'effector'
import { useStore, useStoreMap } from 'effector-react'
import React, { useCallback, useMemo } from 'react'

import { EstimationReport } from '@gmini/sm-api-sdk/lib/EstimationApi/EstimationRepo/EstimationReport'

import { useHover } from '@gmini/utils'

import {
  useDisabledDynamicGroup,
  useChildrenDynamicHasGrouping,
  useChildrenHasGrouping,
  useParentUserClassifierNodeByDynamicNode,
} from '../../model/group-tree'

import { buildKey, getRefByKey, ReportFlatNode } from '../model/treeModel'

import { useClassifierDynamicConditions } from '../../model/dynamic-conditions.store'

import { CellContent } from './CellContent'
import { ElementContent } from './ElementCell'

import { TableRowWrapper } from './TableContent.styled'
import { RenderColumn, SystemColumn } from './types'

type RowContainerProps = {
  column: RenderColumn | SystemColumn
  item: ReportFlatNode
  parentData$: Store<{ id: number; name: string }[]>
  searchModel: SearchModel
  setCtxMenu: (params: CtxMenuBaseProps<ReportFlatNode>) => void
  nodes$: Store<Nodes>
  rowIndex: number
  classifierId: number
  toggle: () => void
  isExpanded: boolean
}

export const RowContainer = React.memo<RowContainerProps>(
  ({
    column,
    item,
    parentData$,
    searchModel,
    setCtxMenu,
    nodes$,
    rowIndex,
    classifierId,
    toggle,
    isExpanded,
  }) => {
    const { hover, onMouseLeave, onMouseOver } = useHover()
    const searchNodesInfo = useStore(searchModel.searchNodes$)
    const nodes = useStore(nodes$)
    const dynamicGroupConditions = useClassifierDynamicConditions({
      classifierId,
    })

    const getParentGroupByDynamicNode =
      useParentUserClassifierNodeByDynamicNode({ nodes$ })

    const childrenDynamicHasGrouping = useChildrenDynamicHasGrouping({
      classifierId,
    })
    const childrenHasGroping = useChildrenHasGrouping({ classifierId })
    const hasGroupingBranchFunc = useDisabledDynamicGroup({
      dynamicGroupConditions,
      nodes$,
      maxGroupingCount: 0,
    })
    const highlight = useMemo(() => {
      if (!searchNodesInfo?.nodes?.length) {
        return false
      }

      const hasDynamicParents = item.path.some(
        key =>
          getRefByKey(key).type === 'DynamicGeneratedGroup' ||
          getRefByKey(key).type === 'DynamicBaseGroup',
      )

      const node = getNode(nodes, {
        type: apiToNodeTypeMap[item.node.elementType],
        id: item.node.elementId,
      })

      if (node?.type === 'DynamicGeneratedGroupNode' && node.source) {
        const nodeKey = buildKey({
          id: node.source.id,
          type: apiToNodeTypeMap[node.source.type],
        })

        return searchNodesInfo?.nodes?.some(({ node: searchNode }) =>
          searchNode.type === 'DynamicGeneratedGroupNode' && searchNode.source
            ? buildKey({
                type: apiToNodeTypeMap[searchNode.source.type],
                id: searchNode.source.id,
              }) === nodeKey
            : buildKey(searchNode) === nodeKey,
        )
      }

      // GT-490 Найти везде для элемента в динамических подгруппах
      // Костыль для поиска ноды в результатах расчета с группирововками
      // На бэке в результатах расчета BimStandardSize.id === DynamicGeneratedGroup.id
      if (item.node.elementType === 'BimStandardSize' && hasDynamicParents) {
        const dynamicGroup = getNode(nodes, {
          id: item.node.elementId,
          type: 'DynamicGeneratedGroupNode',
        })

        if (
          searchNodesInfo.nodes.some(
            ({ node }) =>
              node.type === 'DynamicGeneratedGroupNode' &&
              node.source?.type === item.node.elementType,
          )
        ) {
          const sameSource = searchNodesInfo.nodes.some(
            ({ node }) =>
              node.type === 'DynamicGeneratedGroupNode' &&
              node.source?.type === dynamicGroup?.source?.type &&
              node.source?.id === dynamicGroup?.source?.id,
          )

          return sameSource
            ? dynamicGroup?.id === item.node.elementId
            : searchNodesInfo.nodes.some(
                ({ node }) =>
                  node.type === 'DynamicGeneratedGroupNode' &&
                  node.id === item.node.elementId,
              )
        } else if (dynamicGroup?.source) {
          return searchNodesInfo.nodes.some(
            ({ node }) =>
              node.id === dynamicGroup.source?.id &&
              buildKey(node) ===
                buildKey({
                  id: dynamicGroup.source.id,
                  type: apiToNodeTypeMap[dynamicGroup.source.type],
                }),
          )
        }
      }

      if (
        searchNodesInfo.nodes.some(
          ({ node }) =>
            node.type === 'DynamicGeneratedGroupNode' &&
            node.source &&
            !hasDynamicParents,
        )
      ) {
        return searchNodesInfo.nodes.some(
          ({ node }) =>
            node.type === 'DynamicGeneratedGroupNode' &&
            node.source &&
            buildKey({
              id: node.source?.id,
              type: apiToNodeTypeMap[node.source.type],
            }) ===
              buildKey({
                id: item.node.elementId,
                type: apiToNodeTypeMap[item.node.elementType],
              }),
        )
      }

      return searchNodesInfo.nodes.some(
        ({ node }) =>
          buildKey(node) ===
          buildKey({
            id: item.node.elementId,
            type: apiToNodeTypeMap[item.node.elementType],
          }),
      )
    }, [item.node, item.path, nodes, searchNodesInfo])

    const parentEntityName = useStoreMap({
      store: parentData$,
      keys: [item.node],
      fn: (nodes, [node]) => {
        if (EstimationReport.ReportResult.TreeBimItem.is(node)) {
          const parentData = nodes.find(parent => parent.id === node.source.id)
          return parentData ? parentData.name : ''
        }
        return ''
      },
    })

    const onContextMenu = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        setCtxMenu({
          coords: { x: e.clientX, y: e.clientY },
          item,
        })
      },
      [item, setCtxMenu],
    )

    const cellRender = useCallback(() => {
      const background = rowIndex % 2 !== 0 ? '#f4f4f880' : undefined
      if (column.type === 'System') {
        const dynamicNode =
          nodes.DynamicGeneratedGroupNode?.[item.node.elementId] ||
          nodes.DynamicBaseGroupNode?.[item.node.elementId] ||
          null
        let userClassifierGroupNode: UserClassifierGroupNode | null = null
        let hasGroupingChild = false
        let hasGroupingBranch = false
        let virtualStandardSize = false

        const parentUserClassifierGroup: UserClassifierGroupNode | null =
          dynamicNode ? getParentGroupByDynamicNode(dynamicNode) : null

        if (item.node.elementType === 'UserClassifierGroup') {
          hasGroupingChild = childrenHasGroping(item.node.elementId)
        }

        if (dynamicNode) {
          hasGroupingChild = childrenDynamicHasGrouping(dynamicNode, nodes)

          if (isDynamicBaseGroupNode(dynamicNode)) {
            userClassifierGroupNode =
              nodes.UserClassifierGroupNode[dynamicNode.sourceGroupId] || null
          }

          if (isDynamicGeneratedGroupNode(dynamicNode)) {
            userClassifierGroupNode = dynamicNode.source
              ? nodes.UserClassifierGroupNode[dynamicNode.source.id] || null
              : null
            virtualStandardSize = !!(
              parentUserClassifierGroup as UserClassifierGroupWithElementsNode | null
            )?.children
              ?.filter(c => c.type === 'BimStandardSizeReferenceNode')
              ?.every(ref => {
                const node = getNode(nodes, ref)

                return node?.element.id !== dynamicNode.source?.id
              })
          }

          hasGroupingBranch = parentUserClassifierGroup
            ? hasGroupingBranchFunc(parentUserClassifierGroup)
            : false
        }

        if (!hasGroupingBranch && parentUserClassifierGroup) {
          hasGroupingBranch = dynamicGroupConditions.some(
            ({ sourceGroupId }) =>
              parentUserClassifierGroup.id === sourceGroupId,
          )
        }

        const hasGroupingCurrentGroup = dynamicGroupConditions.some(
          ({ sourceGroupId }) =>
            userClassifierGroupNode &&
            userClassifierGroupNode.id === sourceGroupId,
        )

        if (hasGroupingCurrentGroup) {
          hasGroupingBranch = true
        }

        return (
          <TableRowWrapper
            highlighted={!!highlight}
            onContextMenu={onContextMenu}
            background={background}
            style={{
              minWidth: '260px',
            }}
          >
            <ElementContent
              item={
                dynamicNode &&
                isDynamicGeneratedGroupNode(dynamicNode) &&
                dynamicNode?.source
                  ? { ...item, source: dynamicNode.source }
                  : item
              }
              virtualStandardSize={virtualStandardSize}
              parentEntityName={parentEntityName}
              hasGroupingChild={hasGroupingChild}
              hasGroupingBranch={hasGroupingBranch}
              hasGroupingCurrentGroup={hasGroupingCurrentGroup}
              hasSimpleFieldFilter={
                !!item.node.filter?.includes('SimpleFieldCondition')
              }
              onExpandClick={toggle}
              isExpanded={isExpanded}
              hover={hover}
              onMouseLeave={onMouseLeave}
              onMouseOver={onMouseOver}
            />
          </TableRowWrapper>
        )
      }
      return (
        <TableRowWrapper
          highlighted={!!highlight}
          onContextMenu={onContextMenu}
          background={background}
        >
          <CellContent column={column} item={item} />
        </TableRowWrapper>
      )
    }, [
      childrenDynamicHasGrouping,
      childrenHasGroping,
      column,
      dynamicGroupConditions,
      getParentGroupByDynamicNode,
      hasGroupingBranchFunc,
      highlight,
      hover,
      isExpanded,
      item,
      nodes,
      onContextMenu,
      onMouseLeave,
      onMouseOver,
      parentEntityName,
      rowIndex,
      toggle,
    ])

    return cellRender()
  },
)
