import { createStore } from 'effector'

import * as emApi from '@gmini/sm-api-sdk/lib/EstimationApi'
import { omit } from 'ramda'

const removePending = (map: Record<string, boolean>, key: string) =>
  omit([key], map)

const setPending = (map: Record<string, boolean>, key: string) => ({
  ...map,
  [key]: true,
})

export const createPendingVersionByDatesKey = ({
  estimationId,
  versionDate,
}: {
  estimationId: number
  versionDate: string
}) => `${estimationId}:${versionDate}`

export const pendingMapVersionByDates$ = createStore<Record<string, boolean>>(
  {},
  { name: 'pendingMapVersionByDates' },
)
  .on(
    emApi.Estimation.fetchVersionByDate.defaultContext.submit.map<string>(
      createPendingVersionByDatesKey,
    ),
    setPending,
  )
  .on(
    emApi.Estimation.fetchVersionByDate.defaultContext.finally.map<string>(
      ({ params }) => createPendingVersionByDatesKey(params),
    ),
    removePending,
  )
