import { Store, restore, guard, Event, sample } from 'effector'
import * as smApi from '@gmini/sm-api-sdk'

import { isNotEmpty } from '@gmini/utils'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { currentUserClassifier$ } from '../../../CurrentUserClassifier'

export function dynamicFlatItemsInitialFetchService<
  T extends { id: number; version: number },
>(currentClassifier$: Store<T | null>, reset: Event<void>) {
  const initialFetch$ = restore(
    smApi.UserClassifier.fetchDynamicGroupFlatItems.doneData.map(Boolean),
    false,
  ).reset(reset)

  const clsUpdates = currentClassifier$.updates
    .filter({ fn: isNotEmpty })
    .map(({ id, version }) => ({
      classifierId: id,
      classifierVersion: version,
    }))

  guard({
    clock: clsUpdates,
    filter: initialFetch$.map(fetched => !fetched),
    target:
      smApi.UserClassifier.fetchDynamicGroupFlatItems.defaultContext.submit,
  })
}

sample({
  clock: api.Estimation.start.defaultContext.doneData,
  source: currentUserClassifier$,
  fn: sourceData => ({
    classifierId: sourceData?.id || null,
    classifierVersion: sourceData?.version || null,
  }),
}).watch(({ classifierId, classifierVersion }) => {
  if (!classifierVersion || !classifierId) {
    return
  }

  smApi.UserClassifier.fetchDynamicGroupFlatItems.defaultContext.submit({
    classifierId,
    classifierVersion,
  })
})
