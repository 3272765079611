import styled from 'styled-components'

type TableCellProps = {
  background?: string
  errorText?: boolean
}

export const TableCell = styled.div`
  padding-left: 12px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: ${(props: TableCellProps) =>
    props.errorText ? '#D9575E' : '#rgba(0, 3, 53, 1)'};
  background: ${(props: TableCellProps) => props.background};
  border-right: 1px solid #e2e4e9;
  height: 30px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
