import { EllipseLoader } from '@gmini/ui-kit'

import {
  LoaderText,
  LoaderWrapper,
  Backdrop,
} from './DynamicGroupLoader.styled'

export const DynamicGroupLoader = ({ pending }: { pending: boolean }) =>
  pending ? (
    <Backdrop>
      <LoaderWrapper>
        <EllipseLoader />
        <LoaderText>
          Идет расчет результатов <br /> группировки...
        </LoaderText>
      </LoaderWrapper>
    </Backdrop>
  ) : null
