import {
  LoaderText,
  LoaderWrapper,
  Backdrop,
} from './DynamicGroupLoader.styled'

export const DynamicGroupError = ({
  isTooMuchFieldsAdded,
}: {
  isTooMuchFieldsAdded?: boolean
}) => (
  <Backdrop>
    <LoaderWrapper>
      <LoaderText>
        Произошла ошибка при применении группировок.
        {isTooMuchFieldsAdded ? (
          <>
            <br />
            (было добавлено слишком много группировок и/или простых полей)
          </>
        ) : (
          ''
        )}
      </LoaderText>
    </LoaderWrapper>
  </Backdrop>
)
