import styled from 'styled-components'

type TableContentProps = {
  height: string
}

export const TableContent = styled.div`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
  z-index: 10000;
  margin-top: 12px;
  transition: height 0.3s;
  margin-bottom: -12px;
  height: ${({ height }: TableContentProps) => (height ? height : 0)};
`

type TableHeadCellProps = {
  lastCell?: boolean
}

export const TableHeadCell = styled.div`
  top: 0;
  z-index: 10000;
  padding-bottom: 3px;
  padding-right: 30px;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  height: 36px;
  display: flex;
  align-items: center;
  color: #a2a3b7;
  &:before {
    ${({ lastCell }: TableHeadCellProps) =>
      !lastCell &&
      'content: "";position: absolute; right: 0; z-index: 100;width: 1px;height: 20px;background: #e2e4e9;'};
  }
  padding-left: 12px;
`

type TableHeadWrapProps = {
  width: number
}

export const TableHeadWrap = styled.div`
  background: rgba(226, 228, 233, 0.5);
  border: 1px solid transparent;
  border-radius: 5px;
  width: ${({ width }: TableHeadWrapProps) => (width ? `${width}px` : '100%')};
  height: 36px;
`

type TableRowProps = {
  height?: number
  hover?: boolean
  highlighted?: boolean
  background?: string
}

export const TableRowWrapper = styled.div`
  z-index: -1;
  background: ${({ highlighted, background }: TableRowProps) =>
    highlighted ? 'rgba(125, 177, 255, 0.5)' : background};
`

export type TreeHeadProps = {
  height?: string
}

export const DimensionText = styled.span`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
`

export const HeaderText = styled(DimensionText)`
  color: #f5a623;
`

export const VirtualizedTableWrapper = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  margin: 22px 0 0 14px;
`
