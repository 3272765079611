import styled from 'styled-components'

export const WithDotsIconRoot = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
`

type DotsWrapProps = {
  withIcon?: boolean
}

export const DotsWrap = styled.div`
  display: flex;
  position: absolute;
  right: ${(p: DotsWrapProps) => (p.withIcon ? '8px' : '-1px')};
  bottom: -1px;
`

export type DotProps = {
  isGreen?: boolean
}

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${(p: DotProps) => (p.isGreen ? '#0D9966' : '#4c5ecf')};
  border-radius: 50%;

  &:not(:last-child) {
    margin-right: 1px;
  }
`
