import React from 'react'

import { ReportFlatNode } from '../model/treeModel'

import { getCellText } from './CellContent'
import { RenderColumn, SystemColumn } from './types'

const TREE_ELEMENTS_COLUMN_WIDTH = 460
const CELL_SYMBOL_WIDTH = 10
const MAX_COLUMN_WIDTH = 300
const MIN_COLUMN_WIDTH = 100

// Расчёт ширины колонки = Максимальное количество символов ячейки * Ширина символа (CELL_SYMBOL_WIDTH)
export function useColumnWidth({
  columns,
  flatTree,
}: {
  columns: (SystemColumn | RenderColumn)[]
  flatTree: ReportFlatNode[]
}) {
  const maxStringLengthByColumn = React.useMemo(() => {
    const dimensionByKey: Record<string, string> = {}
    const lengthByColumn: Record<string, number> = {}

    columns.forEach(col => {
      if (col.type === 'System') {
        return
      }
      const colKey = `${col.type}:${col.id}`
      dimensionByKey[colKey] = col.dimension || ''

      // Закидываем длину строки ячейки из Header
      lengthByColumn[colKey] = col.name.length
    })

    flatTree.forEach(({ node, leaf }) => {
      node.values.forEach(({ columnId, type, value }) => {
        const colKey = `${type}:${columnId}`
        const prev = lengthByColumn[colKey]
        const dimension = dimensionByKey[colKey]
        const { text: valueContent } = getCellText({
          value,
          leaf,
          showSubtotals: true,
          dimension,
          columnType: type,
        })
        if (!value) {
          return
        }

        if (prev && prev > valueContent.length) {
          return
        }

        lengthByColumn[colKey] = valueContent.length
      })
    })

    return lengthByColumn
  }, [columns, flatTree])

  const columnWidth = React.useCallback(
    ({ index }: { index: number }) => {
      const column = columns[index]

      // Первая колонка с элементами
      if (column.type === 'System') {
        return TREE_ELEMENTS_COLUMN_WIDTH
      }

      const colKey = `${column.type}:${column.id}`
      const result = maxStringLengthByColumn[colKey] * CELL_SYMBOL_WIDTH

      if (result < MIN_COLUMN_WIDTH) {
        return MIN_COLUMN_WIDTH
      }

      if (result > MAX_COLUMN_WIDTH) {
        return MAX_COLUMN_WIDTH
      }

      return result
    },
    [columns, maxStringLengthByColumn],
  )

  return columnWidth
}
