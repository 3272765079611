import React from 'react'

import { Store } from 'effector'

import { SearchModel } from '@gmini/common'
import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'
import { apiToNodeTypeMap } from '@gmini/common/lib/classifier-service/adapters'
import {
  Tooltip,
  VirtualizedTable,
  VTableColumnRenderer,
  VTableItemRenderer,
} from '@gmini/ui-kit'

import { useStore } from 'effector-react'

import { getNode, Nodes } from '@gmini/common/lib/classifier-service'

import { getBimNode } from '@gmini/common/lib/classifier-editor/Search/utils'
import { buildKeyByPath } from '@gmini/common/lib/classifier-editor/ClassifierTree/utils'
import { filterNotExpandedTree } from '@gmini/common/lib/classifier-editor/ClassifierTree/createTree'

import { EstimationReport } from '@gmini/sm-api-sdk/lib/EstimationApi/EstimationRepo/EstimationReport'

import { getViewerRefs } from '@gmini/common/lib/classifier-editor/Common'

import {
  createTableContent,
  getRefByKey,
  ReportFlatNode,
} from '../model/treeModel'

import { useCurrentUserClassifier } from '../../../CurrentUserClassifier'

import { expandModel } from '../model/expandModel'

import {
  DimensionText,
  HeaderText,
  TableHeadCell,
  TableHeadWrap,
  VirtualizedTableWrapper,
} from './TableContent.styled'
import { RowContainer } from './RowContainer'
import { RenderColumn, SystemColumn } from './types'
import { systemColumn } from './systemColumn'
import { createColumnKey } from './createColumnKey'
import { useColumnWidth } from './useColumnWidth'

type RenderTableProps = {
  rootNode: EstimationReport.TreeBimItem | EstimationReport.TreeGroupItem
  tree: EstimationReport.Tree
  columns: EstimationReport.ReportResult.Column[]
  rowHeight: number
  columnHeight: number
  parentData$: Store<{ id: number; name: string }[]>
  searchModel: SearchModel
  selectViewerRefs: (value: Record<string, string[]>) => void
  nodes$: Store<Nodes>
}

export const RenderTableContent = React.memo<RenderTableProps>(
  ({
    rootNode,
    tree,
    columns: _columns,
    rowHeight,
    columnHeight,
    parentData$,
    searchModel,
    selectViewerRefs,
    nodes$,
  }) => {
    const currentClassifier = useCurrentUserClassifier()!

    const nodes = useStore(nodes$)
    const searchNodes = useStore(searchModel.searchNodes$)
    const searchMatched = useStore(searchModel.searchMatched$)

    const expanded = useStore(expandModel.expanded$)

    const disabledTooltipTitle = React.useCallback(
      ({
        node: flatNode,
      }: {
        node: EstimationReport.TreeBimItem | EstimationReport.TreeGroupItem
      }) => {
        const node = getNode(nodes, {
          type: apiToNodeTypeMap[flatNode.elementType],
          id: flatNode.elementId,
        })

        const notSupportedNode =
          node?.type === 'DynamicBaseGroupNode' ||
          (node?.type === 'DynamicGeneratedGroupNode' && !node?.source) ||
          (node?.type === 'DynamicGeneratedGroupNode' &&
            node?.source?.type === 'UserClassifierGroup')

        return notSupportedNode ? 'Поиск папок не поддерживается' : ''
      },
      [nodes],
    )

    const { flatTree, filteredColumns } = React.useMemo(() => {
      const res = createTableContent(rootNode, tree, _columns)
      return res
    }, [_columns, rootNode, tree])

    React.useEffect(() => {
      flatTree.forEach(({ path }) => {
        const key = buildKeyByPath(path)
        expandModel.setItemExpanded({ key, value: true })
      })
      return () => expandModel.resetExpanded()
    }, [flatTree])

    const flatVisibleTree = React.useMemo(
      () => filterNotExpandedTree({ tree: flatTree, expanded }),
      [expanded, flatTree],
    )

    const filteredTree = React.useMemo(() => {
      if (searchNodes?.type === 'filter' && searchNodes?.isolated) {
        const filteredTree = flatVisibleTree.filter(
          ({ path }) => searchMatched[path[path.length - 1]],
        )

        return filteredTree
      }

      return flatVisibleTree
    }, [flatVisibleTree, searchMatched, searchNodes])

    const { ContextMenu, setCtxMenu } = useContextMenu<ReportFlatNode>([
      {
        title: 'Найти везде',
        icon: Icon.SEARCH,
        onClick: async ({ node: flatNode, path }) => {
          if (
            flatNode.elementType === 'UserClassifierGroup' ||
            flatNode.elementType === 'DynamicBaseGroup'
          ) {
            return
          }

          // GT-490 Найти везде для элемента в динамических подгруппах
          // Костыль для поиска ноды в результатах расчета с группирововками
          // На бэке в результатах расчета BimStandardSize.id === DynamicGeneratedGroup.id
          if (
            flatNode.elementType === 'BimStandardSize' &&
            path.some(
              key =>
                getRefByKey(key).type === 'DynamicGeneratedGroup' ||
                getRefByKey(key).type === 'DynamicBaseGroup',
            )
          ) {
            const dynamicGroupNode = getNode(nodes, {
              id: flatNode.elementId,
              type: 'DynamicGeneratedGroupNode',
            })

            if (!dynamicGroupNode) {
              return
            }

            searchModel.setSearchNodes({
              type: 'search',
              nodes: [
                {
                  node: dynamicGroupNode,
                  path: [],
                },
              ],
            })
            const bimNode = getBimNode(dynamicGroupNode, nodes)

            if (bimNode && bimNode.viewerRefs) {
              const viewerRefs = await getViewerRefs(bimNode, nodes)
              if (viewerRefs) {
                selectViewerRefs(viewerRefs)
              }
            }

            return
          }

          searchModel.searchByNodeRef({
            id: flatNode.elementId,
            type: apiToNodeTypeMap[flatNode.elementType],
          })

          const node = getNode(nodes, {
            id: flatNode.elementId,
            type: apiToNodeTypeMap[flatNode.elementType],
          })

          if (!node) {
            return
          }

          const bimNode = getBimNode(node, nodes)

          if (bimNode && bimNode.viewerRefs) {
            const viewerRefs = await getViewerRefs(node, nodes)
            if (viewerRefs) {
              selectViewerRefs(viewerRefs)
            }
          }
        },
        tooltipTitle: disabledTooltipTitle,
        disabled: ({ node }) => !!disabledTooltipTitle({ node }),
      },
    ])

    const columns = React.useMemo(
      () => [
        systemColumn,
        ...filteredColumns.reduce(
          (acc: RenderColumn[], { name, dimension, id, type }) => {
            const key = createColumnKey({ id, type })
            const duplicate = acc.find(
              col =>
                col.name === name &&
                col.dimension === dimension &&
                col.type === type,
            )

            return duplicate
              ? [
                  ...acc.filter((_, i) => acc.indexOf(duplicate) !== i),
                  {
                    id,
                    name,
                    dimension,
                    type,
                    key: [...duplicate.key, key],
                  },
                ]
              : [
                  ...acc,
                  {
                    id,
                    name,
                    dimension,
                    type,
                    key: [key],
                  },
                ]
          },
          [],
        ),
      ],
      [filteredColumns],
    )

    const columnWidth = useColumnWidth({ columns, flatTree })

    const itemRenderer: VTableItemRenderer<
      ReportFlatNode,
      RenderColumn | SystemColumn
    > = React.useCallback(
      ({ item, column, rowIndex }) => {
        const key = buildKeyByPath(item.path)
        const isExpanded = expanded[key]
        return (
          <RowContainer
            column={column}
            item={item}
            parentData$={parentData$}
            searchModel={searchModel}
            setCtxMenu={setCtxMenu}
            key={item.path.join(':')}
            nodes$={nodes$}
            rowIndex={rowIndex}
            classifierId={currentClassifier.id}
            isExpanded={isExpanded}
            toggle={() => {
              const nextExpanded = !isExpanded
              expandModel.setItemExpanded({ key, value: nextExpanded })
            }}
          />
        )
      },
      [
        currentClassifier.id,
        expanded,
        nodes$,
        parentData$,
        searchModel,
        setCtxMenu,
      ],
    )

    const columnRenderer: VTableColumnRenderer<RenderColumn | SystemColumn> =
      React.useCallback(
        (column, colIndex) => {
          const lastCell = filteredColumns.length === colIndex

          const tableHeadCell = (
            <TableHeadCell lastCell={lastCell}>
              <Tooltip
                placement='bottom-start'
                title={
                  column.dimension ? (
                    <>
                      <HeaderText>Условие:</HeaderText>
                      <DimensionText>{`${column.dimension}`}</DimensionText>
                    </>
                  ) : (
                    ''
                  )
                }
              >
                <div>{column.name}</div>
              </Tooltip>
            </TableHeadCell>
          )
          if (columns.length > 1) {
            return tableHeadCell
          } else if (colIndex === 0) {
            return tableHeadCell
          }
        },
        [columns.length, filteredColumns.length],
      )

    return (
      <VirtualizedTableWrapper>
        <VirtualizedTable
          columnWidth={columnWidth}
          columns={columns}
          items={filteredTree}
          rowHeight={rowHeight}
          colHeight={columnHeight}
          columnRenderer={columnRenderer}
          itemRenderer={itemRenderer}
          headerWrapComponent={({ width, content }) => (
            <TableHeadWrap width={width}>{content}</TableHeadWrap>
          )}
        />
        <ContextMenu />
      </VirtualizedTableWrapper>
    )
  },
)
