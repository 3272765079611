import { FetchService } from '@gmini/utils'

import { removeAuthHeader, setAuthHeader } from '@gmini/common/lib/auth/auth'
import { tokenData$ } from '@gmini/common/lib/keycloakAuth'
import { refreshTokenFunc } from '@gmini/common/lib/keycloakAuth/auth/authData'

import * as smApi from '@gmini/sm-api-sdk'

const setManagement = new FetchService({
  baseUrl: '/api/sets',
  refreshTokenFunc,
})
const models = new FetchService({
  baseUrl: '/api/models',
  refreshTokenFunc,
})
const gStationModelNavigation = new FetchService({
  baseUrl: '/api/g-station-model-navigation',
  refreshTokenFunc,
})

export const authApi = new FetchService({ baseUrl: '/api/auth/v1' })

tokenData$.watch(tokenData => {
  if (tokenData?.accessToken) {
    setAuthHeader(
      [setManagement, models, authApi, gStationModelNavigation],
      tokenData.accessToken,
    )
  } else {
    removeAuthHeader([setManagement, models, authApi, gStationModelNavigation])
  }
})

smApi.Auth.User.fetch.use(() =>
  authApi.get(`/auth/current-user`).then(smApi.Auth.User.fetch.toPromise),
)

export function connectSetManagementAndModelRepo() {
  smApi.setManagementConnect(setManagement)
  smApi.modelRepoConnect(models)
  smApi.navigationModelRepoConnect(gStationModelNavigation)
}
