import React from 'react'

import { Box } from '@gmini/ui-kit/lib/Box'

import {
  BimCategoryNode,
  BimElementNode,
  BimFamilyNode,
  BimModelNode,
  BimStandardSizeNode,
  DynamicBaseGroupNode,
  DynamicGeneratedGroupNode,
  UserClassifierGroupNode,
} from '@gmini/common/lib/classifier-service/Node'

import { FormulaeEditorLayout } from '@gmini/ui-kit'

import { useStore } from 'effector-react'

import { DifficultConditions } from '../DifficultConditions'
import { SimpleConditionsContainer } from '../SimpleConditionsContainer'
import { useDisabledGroup } from '../../EstimationEditor/model/group-tree'
import { InfoContainer } from '../../EstimationEditor/common/InfoContainer'
import { InfoContainerText } from '../../EstimationEditor/common/InfoContainer.styled'

import { groupingProcessStatus$ } from '../GroupingsContainer/GroupingContainer.model'

import { DisabledGroupWrapper } from './RuleEditor.styled'

export type RuleEditorProps = {
  currentGroup:
    | UserClassifierGroupNode
    | DynamicBaseGroupNode
    | DynamicGeneratedGroupNode
    | BimElementNode
    | BimFamilyNode
    | BimCategoryNode
    | BimStandardSizeNode
    | BimModelNode
  widthFormulaResizableCol: number
  disabled: boolean
}

export const RuleEditor = ({
  currentGroup,
  widthFormulaResizableCol,
  disabled,
}: RuleEditorProps) => {
  const isDisabledGroup = useDisabledGroup()(currentGroup.id)
  const groupingProcessStatus = useStore(groupingProcessStatus$)

  if (isDisabledGroup) {
    return (
      <DisabledGroupWrapper>
        <InfoContainer
          text={
            <InfoContainerText>
              В данной цепочке папок уже задано условие, вы не можете задать
              одновременно больше одного условия на цепочку папок.
            </InfoContainerText>
          }
        />
      </DisabledGroupWrapper>
    )
  }

  return (
    <>
      <FormulaeEditorLayout
        disabled={disabled || groupingProcessStatus === 'InProgress'}
      >
        <Box pt='16px' pb='20px'>
          <SimpleConditionsContainer
            currentGroup={currentGroup}
            widthFormulaResizableCol={widthFormulaResizableCol}
          />
        </Box>
        <Box pb={5}>
          <DifficultConditions
            currentGroup={currentGroup}
            widthFormulaResizableCol={widthFormulaResizableCol}
          />
        </Box>
      </FormulaeEditorLayout>
    </>
  )
}
