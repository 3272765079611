import React from 'react'

import { InfoContainer } from '../../common/InfoContainer'

import { Backdrop, NotActualText, NotActualWrapper } from './NotActual.styled'

export const NotActual = ({
  actionButton,
  notActualResult,
}: {
  actionButton?: React.ReactNode
  notActualResult: boolean
}) =>
  notActualResult ? (
    <NotActualWrapper>
      <InfoContainer
        text={
          <NotActualText>
            В редакторе формул были внесены изменения.
            <br /> Перезапустите расчет для получения актуальных данных.
          </NotActualText>
        }
        actionButton={actionButton}
        backDrop={<Backdrop />}
        background='#ffedce'
        borderColor='#f5a623'
      />
    </NotActualWrapper>
  ) : null
