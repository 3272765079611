import { combine, restore } from 'effector'
import * as smApi from '@gmini/sm-api-sdk'

import { estimationService } from '../../../../services/estimationService'

const projectList$ = restore(
  smApi.Project.fetchList.defaultContext.doneData.map(({ list }) => list),
  null,
)

export const currentProject$ = combine({
  projects: projectList$,
  estimation: estimationService.estimation.currentEstimation$,
}).map(
  ({ estimation, projects }) =>
    projects?.find(project => project.urn === estimation?.projectUrn) || null,
)
