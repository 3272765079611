import { combine, sample } from 'effector'

import { equals } from 'ramda'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { estimationService } from '../../../services/estimationService'

import { saveSimpleConditionsApi, selectProperties } from './simpleConditions'
import {
  deleteCondition,
  saveComplexFields,
  unsavedConditionsByRule$,
  saveComplexFieldsApi,
  addCondition,
} from './complexFields'

sample({
  clock: selectProperties,
  source: combine({
    estimation: estimationService.estimation.currentEstimation$,
    complexConditionsByRule: unsavedConditionsByRule$,
  }),
  fn: (
    { estimation, complexConditionsByRule },
    { properties, calculationId },
  ) =>
    estimation
      ? {
          calculationId,
          estimationId: estimation.id,
          estimationVersion: estimation.version,
          simpleFields: properties.map(elementPropertyId => ({
            elementPropertyId,
          })),
          complexFields:
            complexConditionsByRule[calculationId]?.map(
              ({ formula, name, unit }) => ({ formula, name, unit }),
            ) || [],
        }
      : null,
}).watch(req => {
  if (req) {
    if (req.complexFields.length === 0 && req.simpleFields.length === 0) {
      api.EstimationCalculation.remove.defaultContext.submit(req)
    } else {
      saveSimpleConditionsApi.submit(req)
    }
  }
})

sample({
  clock: [saveComplexFields, deleteCondition, addCondition],
  source: combine({
    estimation: estimationService.estimation.currentEstimation$,
    complexConditionsByRule: unsavedConditionsByRule$,
    rulesMap: estimationService.estimationCalculation.calculation$,
  }),
  fn: ({ estimation, complexConditionsByRule, rulesMap }, { calculationId }) =>
    estimation
      ? {
          calculationId,
          estimationId: estimation.id,
          estimationVersion: estimation.version,
          simpleFields: rulesMap[calculationId]?.simpleFields || [],
          complexFields:
            complexConditionsByRule[calculationId]?.map(
              ({ name, formula, unit, errorName }) => ({
                name,
                formula,
                unit,
                errorName,
              }),
            ) || [],
          rulesMap,
        }
      : null,
}).watch(params => {
  const existChanged = !equals(
    params?.complexFields.map(({ name, formula, unit }) => ({
      name,
      formula,
      unit,
    })),
    params?.rulesMap?.[params?.calculationId]?.complexFields.map(
      ({ name, formula, unit }) => ({ name, formula, unit }),
    ),
  )

  const existErrors = params?.complexFields.some(({ errorName }) => errorName)

  if (!params || existErrors) {
    return
  }

  const { rulesMap, ...other } = params

  if (other.complexFields.length === 0 && other.simpleFields.length === 0) {
    api.EstimationCalculation.remove.defaultContext.submit({
      calculationId: other.calculationId,
      estimationId: other.estimationId,
      estimationVersion: other.estimationVersion,
    })
  } else if (existChanged) {
    saveComplexFieldsApi.submit({
      ...other,
      complexFields: other.complexFields.map(({ name, formula, unit }) => ({
        name,
        formula,
        unit,
      })),
    })
  }
})
