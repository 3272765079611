import React from 'react'
import { Popover } from '@mui/material'

import { EstimationReport } from '@gmini/sm-api-sdk/lib/EstimationApi'

import { ArrowDown, CompleteIcon } from '@gmini/ui-kit'

import {
  ArrowIconWrapper,
  PopoverContainer,
  RenderItemLayout,
  RenderItemText,
  RenderListItemLayout,
  RenderListItemText,
  SelectedIconWrapper,
  SelectWrapper,
} from './SelectReport.styled'

type ReportNode = EstimationReport.TreeBimItem | EstimationReport.TreeGroupItem

type SelectReportProps = {
  rootReportNodes: ReportNode[]
  onReportChange: (val: ReportNode) => void
}

export const SelectReport = ({
  rootReportNodes,
  onReportChange,
}: SelectReportProps) => {
  const [value, setValue] = React.useState<ReportNode | null>(
    rootReportNodes[0],
  )
  const itemRef = React.useRef<HTMLDivElement | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const dropDownOpened = Boolean(anchorEl)

  React.useEffect(() => {
    if (value) {
      onReportChange(value)
    }
  }, [onReportChange, value])

  const handleClick = () => {
    if (itemRef.current) {
      setAnchorEl(itemRef.current)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <SelectWrapper>
      <RenderItemLayout onClick={handleClick}>
        <RenderItemText selected={dropDownOpened} ref={itemRef}>
          {value?.name}
        </RenderItemText>
        <ArrowIconWrapper>
          <ArrowDown
            color={
              dropDownOpened ? 'rgba(34, 53, 170, 1)' : 'rgba(0, 0, 61, 1)'
            }
          />
        </ArrowIconWrapper>
      </RenderItemLayout>

      <Popover
        open={dropDownOpened}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <PopoverContainer>
          {rootReportNodes.map(node => (
            <RenderListItemLayout
              onClick={() => {
                setValue(node)
                setAnchorEl(null)
              }}
              key={node.externalId}
            >
              <RenderListItemText>{node.name}</RenderListItemText>
              <SelectedIconWrapper>
                {value === node && <CompleteIcon />}
              </SelectedIconWrapper>
            </RenderListItemLayout>
          ))}
        </PopoverContainer>
      </Popover>
    </SelectWrapper>
  )
}
