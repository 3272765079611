import styled from 'styled-components'

export const EstimationReportWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0;
`

export const StatusPanelLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  min-height: 46px;
  padding: 3px 14px;
  border-top: 1px solid #e5e7f1;
  gap: 8px 4px;
`
