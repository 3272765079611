import { Ellipse, Cover } from '@gmini/ui-kit'

export const Pending = (params: { text: string }) => (
  <Cover
    open={true}
    icon={<Ellipse />}
    textColor='#353B60'
    pb='46px'
    title={params.text}
  />
)
