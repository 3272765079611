import { Button } from '@gmini/ui-kit'
import styled from 'styled-components'

export const GroupingLayout = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
`

export const FooterContent = styled.div`
  display: 'flex';
`

export const FooterLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  min-height: 46px;
`
export const Line = styled.div`
  border-top: 1px solid #e5e7f1;
  width: 100%;
  position: fixed;
`

type ApplyButtonProps = {
  disabled?: boolean
}

export const ApplyButton = styled(Button)`
  background: ${({ disabled }: ApplyButtonProps) =>
    disabled ? 'rgba(53, 59, 96, 0.1)' : 'rgba(53, 59, 96, 1)'};
  display: flex;
  align-items: center;
  color: #ffffff;
  text-transform: none;
  transition: opacity 0.3s ease 0s;
  &:hover {
    background: ${({ disabled }: ApplyButtonProps) =>
      disabled ? 'rgba(53, 59, 96, 0.1)' : 'rgba(53, 59, 96, 0.9)'};
    color: #ffffff;
  }
  min-width: 180px;
  min-height: 36px;
  margin-top: 6px;
  margin-right: 14px;
`

export const IconWrap = styled.div`
  display: inline-flex;
  min-width: 19px;
  padding-left: 3px;
  path {
    fill: #fff;
  }
`

export const ApplyButtonText = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
`

export const DisabledGroupingWrapper = styled.div`
  padding: 25px 24px 0 0;
`
