import React, { memo, useEffect } from 'react'
import { useStore } from 'effector-react'

import { Redirect, useParams } from 'react-router-dom'

import { ReadonlyModeProvider } from '@gmini/common'
import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { estimationService } from '../../services/estimationService'

import {
  estimationVersion,
  oldVersionMode$,
} from '../../services/classifierService'

import { EstimationEditorPage } from './EstimationEditorPage'

api.Estimation.fetch.defaultContext.doneData.watch(
  ({ classifierId, classifierVersion }) => {
    smApi.UserClassifier.fetch.defaultContext.submit({
      id: classifierId,
      version: classifierVersion,
    })
  },
)

export const EditorByVersionWrap = memo(() => {
  const { id, version } = useParams<{ id: string; version: string }>()

  const currentEstimation = useStore(
    estimationService.estimation.currentEstimation$,
  )
  const oldVersionMode = useStore(oldVersionMode$)
  const versionReqPending = useStore(estimationVersion.pending)

  useEffect(() => {
    smApi.Project.fetchList.defaultContext.submit()
  }, [])

  useEffect(() => {
    if (!oldVersionMode && !versionReqPending) {
      estimationVersion({
        id: +id,
        version: +version,
      })
    }

    if (oldVersionMode?.active) {
      api.Estimation.fetch.defaultContext.submit({
        id: +id,
        version: +version,
      })
    }
  }, [versionReqPending, oldVersionMode, id, version])

  if (versionReqPending) {
    return null
  }

  if (oldVersionMode && !oldVersionMode?.active) {
    return <Redirect to={`/estimation/${id}`} />
  }

  if (!currentEstimation) {
    return null
  }

  const readonlyMode = oldVersionMode?.active || !!currentEstimation?.readOnly

  return (
    <ReadonlyModeProvider
      value={{
        enabled: readonlyMode,
        description: readonlyMode ? 'Расчёт находится в режиме просмотра' : '',
      }}
    >
      <EstimationEditorPage
        isCurrentVersion={!oldVersionMode?.active}
        currentEstimation={currentEstimation}
      />
    </ReadonlyModeProvider>
  )
})
EditorByVersionWrap.displayName = 'EditorByVersionWrap'
