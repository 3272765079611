import styled, { css } from 'styled-components'
import { IconButton, IconButtonProps } from '@gmini/ui-kit'

import { Link as RouterLink } from 'react-router-dom'

export const EditorPanelHeaderTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #353b60;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const EditorPanelHeader = styled.div`
  display: flex;
  align-items: center;
`

export const EditorPanelHeaderIcon = styled.div`
  margin-right: 10px;
`

export const DynamicTooltipWrapper = styled.div`
  padding: 3px 2px 8px 0px;
`

export const DynamicTooltipHeader = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`

export const DynamicTooltipBody = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 1px;
`

export const CountIconWrap = styled.div`
  margin: 6px -2px 0 -1px;
`

export const DynamicModeIconButton = styled(IconButton)`
  ${(props: IconButtonProps) =>
    !props.disabled &&
    props.selected &&
    'background: rgba(76, 94, 207, 1); path { fill: #fff }; '};
`

export const DynamicModeText = styled.div`
  background: rgba(76, 94, 207, 0.15);
  color: #4c5ecf;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  display: flex;
  align-items: center;
  padding: 1px 15px 1px 12px;
  margin-right: -5px;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
`

type DynamicModeWrapperProps = {
  disabled?: boolean
}

export const DynamicModeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props: DynamicModeWrapperProps) =>
    props.disabled && 'pointer-events: none;'}
`

type BrandContainerProps = {
  active?: boolean
}

export const BrandContainer = styled(RouterLink)`
  display: flex;
  align-items: center;
  height: 50px;
  margin-right: 6px;
  min-height: 100%;
  background: inherit;
  color: #00003d;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.1px;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: default;
  transition: 0.2s ease-out background-color;
  padding: 8px 18px;
  border-radius: 4px;
  position: relative;

  &:hover {
    background: #e5e7f1;
  }

  ${(props: BrandContainerProps) =>
    props.active &&
    css`
      background: #e5e7f1;
    `}
`

export const BrandTitle = styled.div`
  margin-right: 50px;
`

export const BrandSecondaryWrapper = styled.div`
  padding: 0 24px;
`

//TODO: подумать, как решить проблему с артефактами на иконкам в кнопках без такого костыля
export const ImportButton = styled(IconButton)`
  && path[fill] {
    fill: #353b60;
  }

  && path[stroke] {
    stroke: #353b60;
  }

  && g {
    opacity: 0.5;
  }
`
