import React from 'react'
import { IconButton, Trash, Tooltip, Button } from '@gmini/ui-kit'

import {
  CloseButtonWrap,
  Container,
  Content,
  FirstLine,
  WarningDeleteText,
  Popover,
  WarningActions,
  WarningContainer,
} from './ConditionLayout.styled'

type ConditionLayoutProps = {
  onRemove: () => void
  formulaInput: React.ReactNode
  header: React.ReactNode
  noteText: React.ReactNode
  disabled: boolean
}

export const ConditionLayout = React.memo<ConditionLayoutProps>(
  ({ formulaInput, onRemove, header, disabled, noteText }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null,
    )

    const handleClick = React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
      },
      [],
    )

    const handleClose = React.useCallback(() => {
      setAnchorEl(null)
    }, [])

    const handleConfirmRemove = React.useCallback(() => {
      handleClose()
      onRemove()
    }, [handleClose, onRemove])

    const open = Boolean(anchorEl)

    return (
      <Container>
        <Content>
          <FirstLine>{header}</FirstLine>
          {formulaInput}
          {noteText}
        </Content>
        <div>
          <CloseButtonWrap>
            <Tooltip title='Удалить вычислимую колонку'>
              <IconButton
                data-test-id='removeCalculationColumn'
                type='circle'
                onClick={handleClick}
                disabled={disabled}
              >
                <Trash color='rgba(53, 59, 96, 0.25)' />
              </IconButton>
            </Tooltip>

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <WarningContainer>
                <WarningDeleteText>
                  Вы действительно хотите удалить колонку?
                </WarningDeleteText>
                <WarningActions>
                  <Button
                    color='warning'
                    disabled={disabled}
                    onClick={handleConfirmRemove}
                    data-test-id='acceptToRemoveCalculationColumn'
                  >
                    Удалить
                  </Button>
                  <Button
                    color='secondary'
                    onClick={handleClose}
                    data-test-id='declineToRemoveCalculationColumn'
                  >
                    Отмена
                  </Button>
                </WarningActions>
              </WarningContainer>
            </Popover>
          </CloseButtonWrap>
        </div>
      </Container>
    )
  },
)

ConditionLayout.displayName = 'ConditionLayout'
