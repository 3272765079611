import { createEvent, createStore, guard } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'

import { clone } from 'ramda'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { currentCalculation$ } from '../../CurrentCalculation'

export type SelectPropertiesParams = {
  properties: smApi.UserClassifierGroup.Property['id'][]
  estimationId: number
  estimationVersion: number
  calculationId: number
  groupId?: number
}

const setProperties = createEvent<{
  groupId?: number
  properties?: smApi.UserClassifierGroup.Property['id'][]
}>()
export const selectProperties = createEvent<SelectPropertiesParams>()
export const selectedPropertiesById$ = createStore<
  Record<number, smApi.UserClassifierGroup.Property['id'][]>
>({})
  .on(selectProperties, (state, { properties, groupId }) => {
    const next = clone(state)
    if (groupId) {
      next[groupId] = properties || []
    }

    return next
  })
  .on(setProperties, (state, { properties, groupId }) => {
    const next = clone(state)
    if (groupId) {
      next[groupId] = properties || []
    }
    return next
  })

export const saveSimpleConditionsApi =
  api.EstimationCalculation.updateFields.createContext()

export const selectPropertiesPending$ = saveSimpleConditionsApi.pending$

guard({
  source: currentCalculation$.map(calculation => ({
    groupId: calculation?.groupId,
    properties: calculation?.simpleFields.map(
      condition => condition.elementPropertyId,
    ),
  })),
  filter: Boolean,
  target: setProperties,
})
