import styled from 'styled-components'

type NoteProps = {
  adaptiveMode?: boolean
}

export const Note = styled.div`
  display: flex;
  align-items: ${(props: NoteProps) =>
    props.adaptiveMode ? 'flex-start' : 'center'};
  color: #d9575e;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  margin-left: 12px;
  margin-top: 12px;
`

type WarningWrapperProps = {
  adaptiveMode?: boolean
}

export const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin-right: 6px;
  margin-top: ${(props: WarningWrapperProps) =>
    props.adaptiveMode ? '4px' : 0};
`
