import styled from 'styled-components'

export const Container = styled.div`
  padding: 13px 0 0 24px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const InfoMessage = styled.div`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #353b60;
`

export const InfoMessageContainer = styled.div`
  display: flex;
  gap: 8px;
  margin: 24px 40px 10px 0;

  & svg {
    min-width: 20px;
  }
`
