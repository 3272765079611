import styled from 'styled-components'

export const TableNodeCell = styled.div`
  padding: 0;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  height: 30px;
  border-right: 1px solid #e2e4e9;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: #f4f4f8;
  }
`

type TableNodeWrapperProps = {
  paddingLeft: number
}

export const TableNodeIcon = styled.div`
  display: flex;
  align-items: center;
`

export const TableNodeWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${({ paddingLeft }: TableNodeWrapperProps) =>
    paddingLeft ? `${paddingLeft}px` : 0};
  padding-right: 3px;
`

export const TableNodeText = styled.span`
  line-height: 1;
  flex-grow: 1;
  padding-left: 8px;
  font-size: 14px;
  overflow: hidden;
  color: rgba(0, 3, 53, 1);
`

export const TooltipNodeText = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`

type ArrowWrapperProps = {
  expanded?: boolean
  hovered?: boolean
}

export const ArrowWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5px;
  height: 8px;
  transform: ${({ expanded }: ArrowWrapperProps) =>
    expanded ? 'rotate(90deg)' : 'initial'};
  & path {
    ${({ hovered }: ArrowWrapperProps) => hovered && 'fill: #353B60;'};
  }
`

type ExpandButtonProps = {
  visible?: boolean
}
export const ExpandButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  visibility: ${(props: ExpandButtonProps) =>
    props.visible ? 'visible' : 'hidden'};
`
