import * as React from 'react'

import { Groupings } from '@gmini/ui-kit/lib/Groupings/Groupings'

import {
  BimCategoryNode,
  BimElementNode,
  BimFamilyNode,
  BimModelNode,
  BimStandardSizeNode,
  DynamicBaseGroupNode,
  DynamicGeneratedGroupNode,
  isUserClassifierGroupNode,
  UserClassifierGroupNode,
} from '@gmini/common/lib/classifier-service/Node'

import { useReadonlyMode } from '@gmini/common'

import { CurrentGroupPath } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/groupModel'

import * as smApi from '@gmini/sm-api-sdk'

import { useStore } from 'effector-react'

import { buildKeyByPath } from '@gmini/common/lib/classifier-editor/ClassifierTree/utils'

import { dynamicGroupMode$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/dynamicGroupMode'

import { Store } from 'effector'

import { Nodes } from '@gmini/common/lib/classifier-service'

import {
  useDisabledDynamicGroup,
  getUserClassifierGroupNode,
  usePropertyFilteredList,
} from '../../EstimationEditor/model/group-tree'

import { useElementPropertyList } from '../properties/useElementPropertyList'
import { useCurrentUserClassifier } from '../../CurrentUserClassifier'

import { InfoContainer } from '../../EstimationEditor/common/InfoContainer'
import { InfoContainerText } from '../../EstimationEditor/common/InfoContainer.styled'

import { expandModel } from '../../EstimationEditor/EditorTreeWrap/expandModel'

import { useClassifierDynamicConditions } from '../../EstimationEditor/model/dynamic-conditions.store'

import { seoEventManager } from '../../../config'

import { estimationService } from '../../../services/estimationService'

import {
  GroupingLayout,
  DisabledGroupingWrapper,
} from './GroupingsContainer.styled'
import { groupingProcessStatus$ } from './GroupingContainer.model'

type GroupingsContainerProps = {
  currentSelectedGroup:
    | UserClassifierGroupNode
    | DynamicBaseGroupNode
    | DynamicGeneratedGroupNode
    | BimElementNode
    | BimFamilyNode
    | BimCategoryNode
    | BimStandardSizeNode
    | BimModelNode
  pending: boolean
  currentGroupPath: CurrentGroupPath | null
  nodes$: Store<Nodes>
  widthFormulaResizableCol?: number
}

const emptyCondition = {
  groupName: '',
  name: '',
  conditionElementPropertyId: 0,
  order: 0,
}

const MAX_GROUPING_COUNT = 3

export const GroupingsContainer = React.memo<GroupingsContainerProps>(
  ({
    currentSelectedGroup: currGroup,
    pending,
    currentGroupPath,
    nodes$,
    widthFormulaResizableCol,
  }) => {
    const currCls = useCurrentUserClassifier()!
    const expanded = useStore(expandModel.expanded$)
    const groupingProcessStatus = useStore(groupingProcessStatus$)
    const nodes = useStore(nodes$)

    const currentEstimation = useStore(
      estimationService.estimation.currentEstimation$,
    )!

    const groupingCreatePending =
      smApi.DynamicGroup.create.defaultContext.usePending(
        `${currCls.id}:${currGroup.id}`,
      )
    const groupingRemovePending =
      smApi.DynamicGroup.remove.defaultContext.usePending(
        `${currCls.id}:${currGroup.id}`,
      )

    const dynamicGroupMode = useStore(dynamicGroupMode$)
    const dynamicGroupConditions = useClassifierDynamicConditions({
      classifierId: currCls.id,
    })
    const isDynamicGroup = dynamicGroupConditions.some(
      ({ sourceGroupId }) => sourceGroupId === currGroup.id,
    )

    const userClassifierGroup = isUserClassifierGroupNode(currGroup)
      ? currGroup
      : getUserClassifierGroupNode(currGroup, nodes)

    const groupingConditions = React.useMemo(
      () =>
        dynamicGroupConditions.find(
          ({ sourceGroupId }) => sourceGroupId === userClassifierGroup?.id,
        )?.groupingConditions || [{ ...emptyCondition }],
      [dynamicGroupConditions, userClassifierGroup?.id],
    )

    const { readonlyMode } = useReadonlyMode()

    const [groupingConditionsState, setGroupingConditionsState] =
      React.useState(groupingConditions)

    React.useEffect(() => {
      setGroupingConditionsState(
        (groupingConditions || [{ ...emptyCondition }]).sort(
          (a, b) => a.order - b.order,
        ),
      )
    }, [groupingConditions])

    const { propertyElementList: propertyList, pending: listPending } =
      useElementPropertyList()

    const propertyFilteredList = usePropertyFilteredList({
      group: userClassifierGroup,
      dynamicGroupConditions,
      nodes$,
      propertyList,
      groupingConditions,
    })

    const onDeleteGrouping = React.useCallback(() => {
      if (isDynamicGroup) {
        try {
          smApi.DynamicGroup.remove.defaultContext({
            classifierId: currCls.id,
            version: currCls.version,
            sourceGroupId: currGroup.id,
          })
          setGroupingConditionsState([])

          seoEventManager.push({
            event: 'Gtech_Estimation_EstimationGroupByDelete',
            payload: {
              estimationId: currentEstimation.id,
            },
          })
        } catch (error) {
          console.error(error)
        }

        const key = currentGroupPath
          ? buildKeyByPath(currentGroupPath.path)
          : ''

        if (key && expanded[key]) {
          expandModel.setItemExpanded({ key, value: !expanded[key] })
        }
      }
    }, [
      currCls.id,
      currCls.version,
      currGroup.id,
      currentEstimation.id,
      currentGroupPath,
      expanded,
      isDynamicGroup,
    ])

    const disabledGroupingFunc = useDisabledDynamicGroup({
      dynamicGroupConditions,
      nodes$,
      maxGroupingCount: MAX_GROUPING_COUNT,
    })

    const disabledGrouping = disabledGroupingFunc(userClassifierGroup)

    const addEmptyRow = React.useCallback(() => {
      setGroupingConditionsState(prev => [
        ...(prev || []),
        {
          ...emptyCondition,
          order: (prev || [])[Number(prev?.length) - 1]?.order + 1,
        },
      ])
    }, [])

    const onChangeProperty = React.useCallback(
      async (
        nextProperty: smApi.UserClassifierGroup.Property,
        order: number,
      ) => {
        const item = {
          order,
          groupName: nextProperty.groupName,
          name: nextProperty.name,
          conditionElementPropertyId: nextProperty.id,
        }

        let nextConditions =
          groupingConditionsState?.filter(
            ({ conditionElementPropertyId }) =>
              conditionElementPropertyId !== 0,
          ) || []

        if (
          groupingConditionsState?.find(condition => condition.order === order)
        ) {
          nextConditions[order] = item
        } else {
          nextConditions = [...nextConditions, item]
        }

        const groupInfoRes = await smApi.DynamicGroup.create.defaultContext({
          classifierId: currCls.id,
          version: currCls.version,
          sourceGroupId: currGroup.id,
          groupingConditions: nextConditions,
        })

        setGroupingConditionsState(groupInfoRes.groupingConditions)

        seoEventManager.push({
          event: 'Gtech_Estimation_EstimationGroupBySet',
          payload: {
            estimationId: currentEstimation.id,
          },
        })
      },
      [
        currCls.id,
        currCls.version,
        currGroup.id,
        currentEstimation.id,
        groupingConditionsState,
      ],
    )

    const onRemoveRow = React.useCallback(
      async (order: number) => {
        if (
          groupingConditionsState?.find(condition => condition.order === order)
            ?.conditionElementPropertyId === 0
        ) {
          setGroupingConditionsState(prev =>
            (prev || []).filter(
              condition => condition.conditionElementPropertyId !== 0,
            ),
          )
          return
        }

        const groupInfoRes = await smApi.DynamicGroup.create.defaultContext({
          classifierId: currCls.id,
          version: currCls.version,
          sourceGroupId: currGroup.id,
          groupingConditions:
            groupingConditionsState?.filter(
              condition => condition.order !== order,
            ) || [],
        })
        setGroupingConditionsState(groupInfoRes.groupingConditions)

        if ((groupingConditionsState?.length || 0) < 1) {
          onDeleteGrouping()
          return
        }

        seoEventManager.push({
          event: 'Gtech_Estimation_EstimationGroupByDelete',
          payload: {
            estimationId: currentEstimation.id,
          },
        })
      },
      [
        currCls.id,
        currCls.version,
        currGroup.id,
        currentEstimation.id,
        groupingConditionsState,
        onDeleteGrouping,
      ],
    )

    const getValue = React.useCallback(
      (order: number) => {
        const property = groupingConditionsState?.find(
          item => item.order === order,
        )

        if (!property) {
          return null
        }
        return {
          ...property,
          id: property.conditionElementPropertyId,
        }
      },
      [groupingConditionsState],
    )

    if (disabledGrouping) {
      return (
        <DisabledGroupingWrapper>
          <InfoContainer
            text={
              <InfoContainerText>
                В данной цепочке папок уже заданы 3 группировки, вы не можете
                создать одновременно больше 3 группировок на цепочку папок.
              </InfoContainerText>
            }
          />
        </DisabledGroupingWrapper>
      )
    }

    return (
      <GroupingLayout>
        <Groupings
          propertyList={propertyFilteredList}
          pending={
            listPending ||
            pending ||
            groupingCreatePending ||
            groupingRemovePending
          }
          onDeleteGrouping={onDeleteGrouping}
          onChangeProperty={onChangeProperty}
          disabled={
            readonlyMode.enabled ||
            listPending ||
            dynamicGroupMode ||
            groupingProcessStatus === 'InProgress'
          }
          onRemoveRow={onRemoveRow}
          groupingExists={
            !!groupingConditions?.filter(
              ({ conditionElementPropertyId }) =>
                conditionElementPropertyId !== 0,
            ).length
          }
          addRow={addEmptyRow}
          emptyRows={groupingConditionsState.length <= 1}
          getValue={getValue}
          rowsCount={groupingConditionsState?.length || 0}
          notSelectedValue={
            !!groupingConditionsState?.some(
              condition => condition.conditionElementPropertyId === 0,
            )
          }
          rowsLimit={3}
          readOnly={dynamicGroupMode}
          adaptiveMode={Number(widthFormulaResizableCol) < 430}
        />
      </GroupingLayout>
    )
  },
)

GroupingsContainer.displayName = 'Groupings'
