/* eslint-disable react/display-name */
import { useStore } from 'effector-react'

import React, { useEffect, useState } from 'react'

import { combine, sample } from 'effector'

import { useSnackbar } from 'notistack'

import { WithSumIcon } from '@gmini/ui-kit'

import { getNode } from '@gmini/common/lib/classifier-service'

import {
  isReferenceNode,
  NodeRef,
  Ref,
  ReferenceNode,
  UserClassifierGroupNodeRef,
  UserClassifierNode,
} from '@gmini/common/lib/classifier-service/Node'

import {
  isGroupType,
  nodeToApiTypeMap,
} from '@gmini/common/lib/classifier-service/adapters'

import { EditorTree } from '@gmini/common/lib/classifier-editor/ClassifierTree/EditorTree'

import { moveMultiplyFromOwn } from '@gmini/common/lib/classifier-editor/ClassifierTree/multiply/moveMultiplyFromOwn'
import { insertMultiplyFromDeps } from '@gmini/common/lib/classifier-editor/ClassifierTree/multiply/insertMultiplyFromDeps'

import {
  FlatNode,
  isApiFlatNode,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/createTree'

import { useCtxMenuFilterHandlers } from '@gmini/common/lib/components'

import { NodeLayout } from '@gmini/common/lib/classifier-editor/ClassifierTree/NodeLayout'

import { dynamicGroupMode$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/dynamicGroupMode'

import { pendingMapClassifier$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/pendingModel'

import {
  removeAllNodes,
  removeNode,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/model/removeModel'

import {
  currentGroup$,
  setCurrentGroup,
  subscribeSelectNewGroup,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/model/groupModel'

import {
  isGeneralGroupNode,
  ModelStoreService,
  resetChecked,
  SearchModel,
  SearchNode,
  updateChecked,
} from '@gmini/common/lib/classifier-editor'

import { validateMoveFromDeps } from '@gmini/common/lib/classifier-editor/validate/validate-move'

import {
  TreeLoader,
  operationsPending$,
} from '@gmini/common/lib/classifier-editor/TreeLoader'

import * as smApi from '@gmini/sm-api-sdk'

import { resetShowMode } from '@gmini/common/lib/forge-viewer/model/selectModel'

import { getViewerRefs } from '@gmini/common/lib/classifier-editor/Common'

import { ViewerCheckedMap } from '@gmini/common/lib/classifier-editor/FromTreeToViewer/types'

import { classifierService } from '../../../services/classifierService'
import { estimationService } from '../../../services/estimationService'
import { useChildrenHasRule } from '../model/group-tree'

import {
  dependencyCheckedItems$,
  editorCheckedModel,
} from '../model/current/checkedModel'

import { selectedForgeElements$ } from '../model/current/viewerSelection/viewerSelection'

import { treeModel } from '../model/current/editorTreeModel'

import {
  groupingProcessStatus$,
  startGrouping,
} from '../../GroupSettings/GroupingsContainer/GroupingContainer.model'

import { searchSourceModel } from '../model/searchSourceModel'

import { dynamicGroupConditions$ } from '../model/dynamic-conditions.store'
import { filterPanelService } from '../model/filterPanelService'
import { searchModel } from '../model/current/searchModel'

import { hover$, setHover } from './hover'
import { DynamicGroupLoader } from './DynamicGroupLoader'
import { DynamicGroupError } from './DynamicGroupError'
import { WithDotsIcon } from './WithDotsIcon'
import { expandModel } from './expandModel'

const FILTER_LIMIT = 5000

const filteredFlatTree$ = searchModel.filterTree(treeModel.flatTree$)

subscribeSelectNewGroup({
  flatTree$: filteredFlatTree$,
  onCreatedNode: smApi.UserClassifierGroup.create.doneData,
})

const checkedWithTree$ = combine({
  tree: filteredFlatTree$,
  checked: editorCheckedModel.checked$,
})

// Для работы с чекнутыми элементами во вьювере
// TODO После рефакторинга логики fromEditorToViewer выпилить
sample({
  clock: editorCheckedModel.checked$.updates,
  source: checkedWithTree$,
  fn: ({ checked, tree }, currentCheckedMap) => ({
    checked,
    tree,
    currentCheckedMap,
  }),
}).watch(({ checked, currentCheckedMap, tree }) => {
  if (Object.keys(currentCheckedMap).length > 0) {
    const keys = Object.keys(checked)
    const flatNodes = tree.reduce((acc, node) => {
      if (
        isApiFlatNode(node) &&
        keys.some(k => node.path.join(':') === k && checked[k])
      ) {
        acc[node.ref.type + node.ref.id] = node.ref
      }

      return acc
    }, {} as ViewerCheckedMap)
    updateChecked({
      path: 'Own',
      checkedMap: flatNodes,
    })
  } else {
    resetChecked('Own')
    resetShowMode()
  }
})

export const dynamicGroupPending$ = combine([startGrouping.pending$], pending =>
  pending.some(Boolean),
)

export const EditorTreeWrap = ({
  searchModel,
  openedResultCalculating,
  selectViewerRefs,
  currentUserClassifier,
  dependenciesWithModels$,
  seoEventHandler,
}: {
  searchModel: SearchModel
  openedResultCalculating?: boolean
  selectViewerRefs: (value: Record<string, string[]>) => void
  currentUserClassifier: UserClassifierNode
  dependenciesWithModels$: ModelStoreService['dependenciesWithModels$']
  seoEventHandler: (errorText: string) => void
}) => {
  const { searchMatched$, searchNodes$ } = searchModel

  const calculationsMap = useStore(
    estimationService.estimationCalculation.calculation$,
  )

  const selectedPath = useStore(currentGroup$)

  const depsCheckedList = useStore(dependencyCheckedItems$)
  const nodes = useStore(classifierService.nodes$)
  const checked = useStore(editorCheckedModel.checked$)
  const searchNodes = useStore(searchNodes$)
  const pendingMap = useStore(pendingMapClassifier$)
  const dynamicGroupPending = useStore(dynamicGroupPending$)
  const groupingProcessStatus = useStore(groupingProcessStatus$)
  const selectedForgeElements = useStore(selectedForgeElements$)
  const dynamicGroupMode = useStore(dynamicGroupMode$)
  const operationsPending = useStore(operationsPending$)

  const disabledInsertFilteredElements = (node: FlatNode) => {
    const overLimit =
      (selectedForgeElements?.reduce(
        (acc, next) => acc + next.elementForgeExternalIds.length,
        0,
      ) || 0) >= FILTER_LIMIT

    if (node.ref.type === 'UserClassifierGroupWithGroupsNode') {
      return 'Нельзя вставить элементы в папку с папками'
    } else if (overLimit) {
      return `Невозможно добавить более ${FILTER_LIMIT} элементов`
    }

    return ''
  }

  const findAnywhere = React.useCallback(
    async (ref: Ref<SearchNode['node']>, path: string[]) => {
      let node = getNode(nodes, ref)
      if (node && isReferenceNode(node)) {
        node = getNode(nodes, node.element)
      }

      if (!node) {
        return
      }

      searchModel.setSearchNodes({ type: 'search', nodes: [{ node, path }] })

      const viewerRefs = await getViewerRefs(node, nodes)

      if (viewerRefs) {
        selectViewerRefs(viewerRefs)
      }
    },
    [nodes, searchModel, selectViewerRefs],
  )

  const flatTree = useStore(filteredFlatTree$)
  const hoverKey = useStore(hover$)
  const dynamicGroupConditions = useStore(dynamicGroupConditions$)
  const [firstSelected, setFirstSelected] = useState(false)

  useEffect(() => {
    if (!firstSelected && flatTree.length) {
      const firstNode = flatTree[0]
      setCurrentGroup({ path: firstNode.path })
      setFirstSelected(true)
    }
  }, [firstSelected, flatTree])

  const removeRef = React.useCallback(
    (ref: UserClassifierGroupNodeRef | Pick<ReferenceNode, 'type' | 'id'>) => {
      removeNode(ref, currentUserClassifier!)
    },
    [currentUserClassifier],
  )

  const deleteSelected = React.useCallback(
    (items: NodeRef[]) => {
      const { id, version } = currentUserClassifier!
      removeAllNodes({
        id,
        version,
        items: items.map(item => ({
          ...item,
          type: nodeToApiTypeMap[item.type] as
            | smApi.BimReference['type']
            | smApi.UserClassifierGroup['type'],
        })),
      })
    },
    [currentUserClassifier],
  )

  const onPending = React.useCallback(
    (key: string) => !!pendingMap[key],
    [pendingMap],
  )

  const allowToCreate = React.useCallback(
    (node: FlatNode) => node.ref.type !== 'UserClassifierGroupWithElementsNode',
    [],
  )

  const isSpecialNode = React.useCallback(
    (node: FlatNode) => {
      if (isApiFlatNode(node) && isGroupType(node.ref.type)) {
        return Object.values(calculationsMap).some(
          calculation => calculation && calculation.groupId === node.ref.id,
        )
      }
      return false
    },
    [calculationsMap],
  )

  const { enqueueSnackbar } = useSnackbar()

  const notify = React.useCallback(
    (reason: string) => {
      enqueueSnackbar(reason, {
        variant: 'error',
      })

      seoEventHandler(reason)
    },
    [enqueueSnackbar, seoEventHandler],
  )

  const childrenHasRule = useChildrenHasRule()

  React.useEffect(() => {
    if (currentUserClassifier) {
      smApi.UserClassifierTree.GroupItem.getList.defaultContext.submit({
        classifierId: currentUserClassifier.id,
        classifierVersion: currentUserClassifier.version,
      })
    }
  }, [currentUserClassifier])

  const onInsertFilteredElements = React.useCallback(
    (ref: UserClassifierGroupNodeRef) => {
      if (
        selectedForgeElements?.length &&
        (ref.type === 'UserClassifierGroupWithElementsNode' ||
          ref.type === 'UserClassifierEmptyGroupNode')
      ) {
        smApi.UserClassifier.createRefsFromExternalIds.defaultContext({
          id: currentUserClassifier!.id,
          version: currentUserClassifier!.version,
          parentGroupId: ref.id,
          items: selectedForgeElements,
        })
      }
    },
    [currentUserClassifier, selectedForgeElements],
  )

  const getCountElementsFromSearch = React.useCallback(
    () => searchNodes?.nodes?.length || 0,
    [searchNodes?.nodes?.length],
  )

  const filterHandlers = useCtxMenuFilterHandlers({
    nodes,
    checked,
    dynamicMode: dynamicGroupMode,
    filterPanelService,
    currentEntity: currentUserClassifier,
  })

  if (!currentUserClassifier) {
    return null
  }

  return (
    <>
      {/* TODO привести все лоадеры к единому стилю с пропсами pending: boolean, text: ReactNode */}
      <DynamicGroupLoader
        pending={dynamicGroupPending || groupingProcessStatus === 'InProgress'}
      />
      {dynamicGroupMode && groupingProcessStatus === 'Error' && (
        <DynamicGroupError />
      )}

      {dynamicGroupMode && groupingProcessStatus === 'ErrorTimedOut' && (
        <DynamicGroupError isTooMuchFieldsAdded />
      )}

      <TreeLoader />
      <EditorTree
        dynamicGroupsConditions={dynamicGroupConditions}
        isSpecialNode={isSpecialNode}
        notify={notify}
        nodes$={classifierService.nodes$}
        dynamicMode$={dynamicGroupMode$}
        currentUserClassifier={currentUserClassifier}
        treeModel={{ ...treeModel, flatTree$: filteredFlatTree$ }}
        checkedModel={editorCheckedModel}
        expandModel={expandModel}
        selectedFromOtherTreeCount={depsCheckedList.length}
        openedResultCalculating={openedResultCalculating}
        onSubmitCreating={(name, { parentNodeRef }) => {
          const parentGroupId = parentNodeRef ? parentNodeRef.id : undefined

          smApi.UserClassifierGroup.create.defaultContext.submit({
            classifierId: currentUserClassifier.id,
            parentClassifierVersion: currentUserClassifier.version,
            parentGroupId,
            name,
          })
        }}
        onCancelCreating={() => {
          treeModel.setInCreateNode(null)
        }}
        setInCreateNode={n => treeModel.setInCreateNode(n)}
        onSubmitEditing={(newName, node) => {
          smApi.UserClassifierGroup.rename.defaultContext.submit({
            classifierId: currentUserClassifier.id,
            parentClassifierVersion: currentUserClassifier.version,
            parentGroupId: node.parentGroupId,
            id: node.id,
            name: newName,
          })
        }}
        onMoveItems={({ target, items }) => {
          moveMultiplyFromOwn({
            currentClassifier: currentUserClassifier,
            items,
            nodes$: classifierService.nodes$,
            target,
          })
        }}
        onInsertFromOtherTree={({ target }) => {
          insertMultiplyFromDeps({
            currentClassifier: currentUserClassifier,
            dependenciesCheckedItems: depsCheckedList,
            nextParentNode: target,
            nodes$: classifierService.nodes$,

            // onSuccessItem: uncheckItem,
          })
        }}
        validateInsertFromOtherTree={({ target, nestingLevel }) =>
          validateMoveFromDeps({
            items: depsCheckedList,
            nodes,
            targetNode: target,
            nestingLevel,
            dynamicMode: dynamicGroupMode,
          })
        }
        onPasteGroup={({ parentGroupId, sourceGroupId }) => {
          if (sourceGroupId) {
            smApi.UserClassifierGroup.groupCopying.defaultContext.submit({
              classifierId: currentUserClassifier.id,
              classifierVersion: currentUserClassifier.version,
              parentGroupId,
              sourceGroupId,
            })
          }
        }}
        onFindAnywhere={findAnywhere}
        onDelete={removeRef}
        onDeleteSelected={deleteSelected}
        searchNodes={searchNodes}
        searchMatched$={searchMatched$}
        hideCtxMenu={operationsPending}
        onPending={onPending}
        allowCreate={allowToCreate}
        selectedPath={selectedPath}
        dependenciesWithModels$={dependenciesWithModels$}
        onSearchSource={searchSourceModel.setSearchSourceData}
        disabledInsertFilteredElements={disabledInsertFilteredElements}
        allowInsertFilteredElements={() => !!selectedForgeElements?.length}
        onInsertFilteredElements={onInsertFilteredElements}
        getCountElementsFromSearch={getCountElementsFromSearch}
        {...filterHandlers}
        renderNodeLayout={({ node, nodeLayoutProps, path, treeItem }) => {
          const nodeKey =
            'id' in treeItem.ref
              ? `${treeItem.ref.type}_${treeItem.ref.id}`
              : treeItem.ref.type

          const hover = hoverKey === nodeKey

          const nextProps = {
            ...nodeLayoutProps,
          }

          nextProps.onClick = () => {
            nodeLayoutProps.onClick?.()

            setCurrentGroup({ path })
          }

          const hasCalculation = Object.values(calculationsMap).some(
            calculation => calculation && calculation.groupId === node.id,
          )

          if (isGeneralGroupNode(node)) {
            const hasRule = childrenHasRule(node.id)

            if (hasCalculation) {
              nextProps.icon = <WithSumIcon icon={nextProps.icon} />
            }

            if (hasRule) {
              nextProps.icon = (
                <WithDotsIcon withIcon={!!hasCalculation} greenDot={hasRule}>
                  {nextProps.icon}
                </WithDotsIcon>
              )
            }
          }

          return (
            <NodeLayout
              {...nextProps}
              hover={hover}
              onMouseLeave={e => {
                setHover(null)
                nextProps.onMouseLeave?.(e)
              }}
              onMouseOver={e => {
                setHover(nodeKey)
                nextProps.onMouseOver?.(e)
              }}
            />
          )
        }}
      />
    </>
  )
}
