import { memo, useEffect, useState } from 'react'

import { useStore } from 'effector-react'

import { ReadonlyModeProvider } from '@gmini/common'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { Page404 } from '@gmini/ui-kit/lib/Page404'

import { EstimationEditorPage } from '../EstimationEditor'
import { estimationService } from '../../services/estimationService'
import { useCurrentEstimationParams } from '../CurrentEstimation'
import { seoEventManager } from '../../config'

api.Estimation.getMostRecent.doneData.watch(({ classifierId }) => {
  smApi.UserClassifier.getMostRecent.defaultContext.submit({
    id: classifierId,
  })
})

smApi.UserClassifierGroup.groupCopying.defaultContext.doneData.watch(
  ({ classifierId, classifierVersion }) => {
    smApi.UserClassifierTree.DynamicGroupItem.getList.defaultContext.submit({
      classifierId,
      classifierVersion,
    })
  },
)

export const EstimationEditorPageWrap = memo(() => {
  const currentEstimation = useStore(
    estimationService.estimation.currentEstimation$,
  )
  const readonlyMode = !!currentEstimation?.readOnly
  const params = useCurrentEstimationParams()
  const [resourceNotFound, setResourceNotFound] = useState(false)
  const [isOpenEventSended, setIsOpenEventSended] = useState(false)

  useEffect(() => {
    if (currentEstimation && !isOpenEventSended) {
      seoEventManager.push({
        event: 'Gtech_Estimation_EstimationOpen',
        payload: {
          estimationId: currentEstimation?.id,
        },
      })
      setIsOpenEventSended(true)
    }
  }, [currentEstimation, isOpenEventSended])

  useEffect(() => {
    if (currentEstimation) {
      smApi.UserClassifier.fetchIsUserClassifierLocked.defaultContext.submit({
        id: currentEstimation.classifierId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEstimation?.classifierId])

  useEffect(() => {
    smApi.Project.fetchList.defaultContext.submit()
  }, [])

  useEffect(() => {
    if (params) {
      api.Estimation.getMostRecent.defaultContext({ id: params.id })
    }
  }, [params])

  useEffect(() => {
    const subscription =
      api.Estimation.getMostRecent.defaultContext.failData.watch(({ res }) => {
        const { errorCode } = res.data.data
        if (errorCode === 'RESOURCE_NOT_FOUND') {
          setResourceNotFound(true)
        }
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [params])

  if (resourceNotFound) {
    return <Page404 />
  }

  if (!params || !currentEstimation) {
    return null
  }

  return (
    <ReadonlyModeProvider
      value={{
        enabled: readonlyMode,
        description: readonlyMode ? 'Расчёт находится в режиме просмотра' : '',
      }}
    >
      <EstimationEditorPage
        currentEstimation={currentEstimation}
        isCurrentVersion={true}
      />
    </ReadonlyModeProvider>
  )
})

EstimationEditorPageWrap.displayName = 'EstimationEditorPageWrap'
