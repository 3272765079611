import {
  BimNode,
  DynamicBaseGroupNode,
  DynamicGeneratedGroupNode,
  isBimNode,
  isDynamicBaseGroupNode,
  isDynamicGeneratedGroupNode,
  isReferenceNode,
  isUserClassifierGroupNode,
  ReferenceNode,
  UserClassifierGroupNode,
  Node,
  DynamicBimElementReferenceNode,
  isDynamicBimElementReferenceNode,
} from '@gmini/common/lib/classifier-service/Node'

export type EstimationTreeNode =
  | UserClassifierGroupNode
  | BimNode
  | ReferenceNode
  | DynamicBaseGroupNode
  | DynamicGeneratedGroupNode
  | DynamicBimElementReferenceNode

export const isEstimationTreeNode = (node: Node): node is EstimationTreeNode =>
  isUserClassifierGroupNode(node) ||
  isBimNode(node) ||
  isReferenceNode(node) ||
  isDynamicBaseGroupNode(node) ||
  isDynamicGeneratedGroupNode(node) ||
  isDynamicBimElementReferenceNode(node)
