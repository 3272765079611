import { EstimationReport } from '@gmini/sm-api-sdk/lib/EstimationApi'

import { SystemColumn } from './types'

export function createColumnKey({
  id,
  type,
}: {
  id: number
  type: EstimationReport.ReportResult.Column['type'] | SystemColumn['type']
}) {
  return `${type}:${id}`
}
