import styled from 'styled-components'

export const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 580px;
`

export const Name = styled.div`
  margin-top: 16px;
  color: #353b60;
  letter-spacing: 0.15px;
  margin-bottom: 5px;
`

export const FolderName = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  margin: 0 24px;
`

export const Icon = styled.div`
  width: 96px;
  min-width: 96px;
`

export const TextWrapper = styled.div`
  display: flex;
  gap: 4px;
`

export const TextMessage = styled.div`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #3f3f3f;
  text-align: center;
`

export const GroupName = styled.span`
  font-weight: bold;
  margin-right: 5px;
`

export const WarningIconWrapper = styled.div`
  min-width: 20px;
`
