import {
  DynamicGeneratedGroupNode,
  NodeRef,
} from '@gmini/common/lib/classifier-service/Node'

import { EstimationReport } from '@gmini/sm-api-sdk/lib/EstimationApi/EstimationRepo/EstimationReport'

import { createColumnKey } from '../Table/createColumnKey'

export type ReportFlatNode = {
  path: string[]
  node: EstimationReport.TreeGroupItem | EstimationReport.TreeBimItem
  source?: DynamicGeneratedGroupNode['source']
  leaf: boolean
}

export function createTableContent(
  rootNode: EstimationReport.TreeBimItem | EstimationReport.TreeGroupItem,
  data: EstimationReport.Tree,
  columns: EstimationReport.ReportResult.Column[],
) {
  // Для оптимизации алгоритма индексируем данные заранее
  const childrenIndex: Record<string, string[] | undefined> = {}
  const dataIndex: Record<string, EstimationReport.Tree[number]> = {}

  data.forEach(item => {
    dataIndex[item.externalId] = item
    if (!item.parentExternalId) {
      return
    }

    const parentChildren = childrenIndex[item.parentExternalId] || []
    parentChildren.push(item.externalId)
    childrenIndex[item.parentExternalId] = parentChildren
  })

  const flatTree: ReportFlatNode[] = []
  const cols: Set<EstimationReport.ReportResult.Column> = new Set()

  function recursive({
    node,
    path,
  }: {
    node: ReportFlatNode['node']
    path: string[]
  }) {
    const nextPath = [...path, `${node.elementId}:${node.elementType}`]
    const childrenLinks = childrenIndex[node.externalId] || []
    const children = childrenLinks.map(externalId => dataIndex[externalId])

    const leaf = !children.length

    flatTree.push({
      path: nextPath,
      node,
      leaf,
    })

    if (!leaf) {
      children.forEach(ch => recursive({ node: ch, path: nextPath }))
    }

    if (leaf && node.values.length) {
      node.values.forEach(value => {
        columns.forEach(col => {
          if (cols.has(col)) {
            return
          }
          if (
            createColumnKey({ id: value.columnId, type: value.type }) ===
            createColumnKey(col)
          ) {
            cols.add(col)
          }
        })
      })
    }
  }
  recursive({ node: rootNode, path: [] })

  return { flatTree, filteredColumns: [...cols] }
}

export function getRefByKey(key: string) {
  const [id, type] = key.split(':')

  return { type, id: Number(id) } as {
    type:
      | EstimationReport.TreeGroupItem['elementType']
      | EstimationReport.TreeBimItem['elementType']
    id: number
  }
}

export function buildKey({
  id,
  type,
}: {
  id: number
  type: NodeRef['type'] | 'UserClassifierGroupNode'
}): string {
  return `${type}_${id}`
}
