import React from 'react'

import { Tabs, Popup, IconButton, RoundedCross } from '@gmini/ui-kit/lib'

import { Title, Header, Container, DetailedHelpLink } from './HelpDialog.styled'
import { OperationTabContent } from './OperationTabContent'
import { ExampleTabContent } from './ExampleTabContent'

export type HelpDialogProps = {
  open: boolean
  onClose: () => void
}

export const HelpDialog: React.FC<HelpDialogProps> = ({ onClose, open }) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)

  const onChangeTab = React.useCallback(
    (tabIndex: number) => setActiveTabIndex(tabIndex),
    [],
  )

  return (
    <Popup open={open} onClose={onClose} width='650px' minHeight='525px'>
      <Container>
        <Header>
          <Title>Справка</Title>
          <IconButton type='circle'>
            <RoundedCross onClick={onClose} />
          </IconButton>

          <DetailedHelpLink
            target='_blank'
            rel='noreferrer'
            href='https://gmini.freshdesk.com/support/solutions/articles/67000661366-%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B-%D1%84%D0%BE%D1%80%D0%BC%D1%83%D0%BB-%D0%B4%D0%BB%D1%8F-%D0%BC%D0%BE%D0%B4%D1%83%D0%BB%D1%8F-%D0%BE%D0%B1%D1%8A%D0%B5%D0%BC%D1%8B'
          >
            Подробная справка
          </DetailedHelpLink>
        </Header>

        <Tabs
          onChangeTab={onChangeTab}
          activeTabIndex={activeTabIndex}
          tabs={[
            {
              title: 'Операции',
              content: <OperationTabContent />,
            },
            {
              title: 'Примеры',
              content: <ExampleTabContent />,
            },
          ]}
          headerStyles={{ marginRight: '-24px' }}
        />
      </Container>
    </Popup>
  )
}
