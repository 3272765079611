import styled from 'styled-components'
//TODO разобраться с z-index по проектам
export const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100001;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
`

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`

export const LoaderText = styled.div`
  margin-top: 12px;
  font-family: Open-Sans, Helvetica, Sans-Serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #353b60;
`
