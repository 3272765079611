import * as React from 'react'
import { merge, Event, sample } from 'effector'
import { useSnackbar } from 'notistack'
import { prop } from 'ramda'

// import { migrateFailMessage, migrateSystemError } from '@gmini/common/lib/classifier-editor'

import { ImportErrorNotify } from '@gmini/common'
import * as smApi from '@gmini/sm-api-sdk'

import { bimFileErrorMessageMap } from '@gmini/common/lib/classifier-editor/SelectDependencies/importErrorMessageMap'

import {
  classifierService,
  selectDependenciesClassifierService,
} from '../../services/classifierService'
import { estimationService } from '../../services/estimationService'
import { messageMap, notShownMessages } from '../../services/messageMap'
import { notificationService } from '../../services/notificationService'
import { userClassifierRepoService } from '../../services/userClassifierRepoServiceConnect'
import { seoEventManager } from '../../config'

const bimFileImportErrorEvent = notificationService.message
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))
  .filter({
    fn: payload =>
      smApi.BimFile.isBimFile(payload) ||
      smApi.BimFile.isModelRepositoryBimFile(payload),
  })
  .filter({
    fn: ({ status, errorCode }: smApi.BimFile) =>
      status === 'ImportError' && errorCode !== 'StructureError',
  }) as Event<smApi.BimFile>

const bimFileImportErrorMessage = bimFileErrorMessageMap(
  bimFileImportErrorEvent,
)

const seoEventErrorHandler = (errorMessage: string, estimationId?: number) => {
  seoEventManager.push({
    event: 'Gtech_Estimation_Error',
    payload: {
      estimationId,
      errorText: errorMessage,
      errorType: 'red',
    },
  })
}

export const Notifications = React.memo<Record<string, unknown>>(() => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  React.useEffect(() => {
    const openSnackbar = (message: string) => {
      enqueueSnackbar(message, {
        variant: 'error',
      })
    }

    const openImportErrorSnackbar = (
      data: {
        text: string
        name: string
        version: number
      } | null,
    ) => {
      if (!data) {
        return
      }
      enqueueSnackbar(data, {
        variant: 'error',
        // eslint-disable-next-line react/display-name
        content: key => (
          <ImportErrorNotify
            key={key}
            text={data.text}
            name={data.name}
            version={data.version}
          />
        ),
      })
    }

    sample({
      source: estimationService.estimation.currentEstimation$,
      clock: [message, iOTSError.map(prop('message'))],
      fn: (estimation, error) => ({ estimation, error }),
    }).watch(({ estimation, error }) => {
      if (error) {
        seoEventErrorHandler(error, estimation?.id)
      }
    })
    const failSubscribtion = message.watch(error => {
      openSnackbar(error)
    })
    const iOTSSubscribtion = iOTSError.watch(({ errors, message }) => {
      console.error(errors)
      openSnackbar(message)
    })
    const bimFileImportErrorSubscription = bimFileImportErrorMessage.watch(
      openImportErrorSnackbar,
    )

    return () => {
      failSubscribtion.unsubscribe()
      iOTSSubscribtion.unsubscribe()
      bimFileImportErrorSubscription.unsubscribe()
    }
  }, [closeSnackbar, enqueueSnackbar])

  return null
})

Notifications.displayName = 'Notifications'

const formatErrorMessage = (message: string, code: string | null) =>
  `${message}`

const unexpected = (errorCode: string | null) =>
  `${unexpectedText}. [${errorCode}]`

const unexpectedText =
  'Непредвиденная ошибка. Пожалуйста, обратитесь к разработчикам'

const fail = merge([
  classifierService.anyRequestFail,
  selectDependenciesClassifierService.anyRequestFail,
  // migrateFailMessage,
  // migrateSystemError,
]).map(({ errorCode, mappedMessage }) => mappedMessage || unexpected(errorCode))

const iOTSError = merge([
  userClassifierRepoService.IOTSError,
  selectDependenciesClassifierService.anyRequestIOTSError,
  classifierService.anyRequestIOTSError,
  estimationService.IOTSError,
]).map(({ status: errorCode, errors }) => ({
  errors,
  message: unexpected(errorCode),
}))

const estimationServiceMessage = estimationService.fail
  .map(prop('data'))
  .map(prop('errorCode'))
  //TODO FSK-594 не отображаем юзеру ошибку рефреша токена
  .filter({ fn: code => !notShownMessages.includes(code) })
  .map(code =>
    formatErrorMessage(
      messageMap[code as keyof typeof messageMap] || unexpectedText,
      code,
    ),
  )

const message = merge([fail, estimationServiceMessage])
