import { WarningCycleBig, Cover } from '@gmini/ui-kit'
import React from 'react'

import { ActionWrapper } from './Refresh.styled'

export const Refresh = (params: { action?: React.ReactNode }) => (
  <Cover
    open={true}
    icon={<WarningCycleBig />}
    title={
      <>
        Нет данных для отображения. <br /> Запустите расчет для получения
        актуальных данных.
        <br />
        <ActionWrapper>{params.action}</ActionWrapper>
      </>
    }
    textColor='#353B60'
    pb='46px'
  />
)
