import { prop } from 'ramda'

import * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { ExternalEvent } from '@gmini/common/lib/Services/EstimationService/ApiService'

import { notificationService } from '../../services/notificationService'

export const created = notificationService.message
  .filter({ fn: ExternalEvent.Create.is })
  .map(prop('payload'))

export const folderCreated = created.filter({ fn: api.EstimationRepoFolder.is })
export const estimationCreated = created.filter({ fn: api.Estimation.is })
