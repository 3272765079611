import { setStoreName } from 'effector-root'
import { createGate } from 'effector-react'

import type { node } from '@gmini/common/lib/classifier-service'

import type * as api from '@gmini/sm-api-sdk/lib/EstimationApi'

import { domain } from './domain'

export const Estimation = createGate<{ readonly id: null | number }>({
  domain,
  defaultState: { id: null },
  name: 'Estimation',
})

export const estimationId$ = Estimation.state.map(state => state.id || null)

setStoreName(estimationId$, 'estimationId$')

export const estimation$ = domain.store<null | api.Estimation>(null, {
  name: 'estimation$',
})

export const classifier$ = domain.store<null | node.UserClassifierNode>(null, {
  name: 'classifier$',
})
