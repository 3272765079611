import { apiToNodeTypeMap } from '@gmini/common/lib/classifier-service/adapters'

import {
  Folder,
  FolderDynamicGenerated,
  FolderGrouping,
  CategoryBlue,
  FamilyBlue,
  CubesBlue,
  FolderGroupingChild,
} from '@gmini/ui-kit'
import { BimNodeIcon } from '@gmini/common/lib/classifier-editor/ClassifierTree/BimNodeIcon'

import { EstimationReport } from '@gmini/sm-api-sdk/lib/EstimationApi/EstimationRepo/EstimationReport'

export const getIcon = (
  type:
    | EstimationReport.TreeGroupItem['elementType']
    | EstimationReport.TreeBimItem['elementType'],
) => {
  const _type = apiToNodeTypeMap[type]
  switch (_type) {
    case 'BimModelNode':
    case 'BimCategoryNode':
    case 'BimFamilyNode':
    case 'BimStandardSizeNode':
    case 'BimElementNode':
      return <BimNodeIcon type={_type} />
    case 'DynamicBaseGroupNode':
      return <FolderGrouping color='#4C5ECF' />

    case 'DynamicGeneratedGroupNode':
      return <FolderDynamicGenerated />

    case 'UserClassifierGroupNode':
      return <Folder />

    default:
      throw new Error(`Unexpected type ${_type} `)
  }
}

export const getIconDynamic = (
  type:
    | EstimationReport.TreeGroupItem['elementType']
    | EstimationReport.TreeBimItem['elementType'],
) => {
  const _type = apiToNodeTypeMap[type]
  switch (_type) {
    case 'BimCategoryNode':
      return <CategoryBlue />
    case 'BimFamilyNode':
      return <FamilyBlue />
    case 'BimStandardSizeNode':
      return <CubesBlue />
    case 'UserClassifierGroupNode':
      return <FolderGroupingChild />
    case 'DynamicBaseGroupNode':
    case 'DynamicGeneratedGroupNode':
      return <FolderDynamicGenerated />

    default:
      throw new Error(`Unexpected type ${_type} `)
  }
}
