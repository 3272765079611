import { createSelectSourcePanel } from '@gmini/common/lib/components/FilterPanel/components/SelectSourcePanel'

import { classifierService } from '../../../services/classifierService'
import { currentUserClassifier$ } from '../../CurrentUserClassifier'

import { dependencyCheckedModel } from './current/checkedModel'

import { filterPanelService } from './filterPanelService'

export const { SelectSourcePanel, selectedSourceNodes$ } =
  createSelectSourcePanel({
    checkedModel: dependencyCheckedModel,
    nodes$: classifierService.nodes$,
    sources: filterPanelService.sources,
    currentEntity$: currentUserClassifier$,
  })
