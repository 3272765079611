import * as smApi from '@gmini/sm-api-sdk'
import {
  combine,
  createEvent,
  createStore,
  forward,
  guard,
  merge,
  restore,
  sample,
} from 'effector'

import { isNotEmpty } from '@gmini/utils'

import { NotificationEvent } from '@gmini/sm-api-sdk/lib/EstimationApi//Notifications/NotificationEvent'

import { notificationService } from '../../../../services/notificationService'

import { selectedGroupNode$ } from '../../../CurrentCalculation'
import { currentUserClassifier$ } from '../../../CurrentUserClassifier'
import { getSourceGroupId } from '../group-tree'

const inclusionStatusEvent = notificationService.message.filter({
  fn: NotificationEvent.InclusionStatusChange.is,
})

type FetchedGroupsMap = Record<string, boolean>

const refetchProperties =
  createEvent<smApi.UserClassifierGroup.Property.PropertyRequest>()

export const setComponentMounted = createEvent<boolean>()
const componentMounted$ = restore(setComponentMounted, false)

const isComponentMounted = componentMounted$.map(mounted => mounted)

forward({
  from: smApi.UserClassifierGroup.Property.List.fetchStandardSizeProperties.done.map(
    ({ params }) => ({ ...params }),
  ),
  to: smApi.UserClassifierGroup.Property.List.fetchElementProperties
    .defaultContext.submit,
})

const fetchedGroupsMap$ = createStore<FetchedGroupsMap>({})
  .on(
    smApi.UserClassifierGroup.Property.List.fetchStandardSizeProperties
      .defaultContext.done,
    (state, { params: { groupId } }) => {
      const newState = { ...state }
      newState[groupId] = true
      return newState
    },
  )
  .reset(
    merge([
      inclusionStatusEvent,
      smApi.UserClassifier.removeDependency.doneData,
    ]),
  )

const dependencyRemoved = sample({
  clock: smApi.UserClassifier.removeDependency.doneData,
  source: selectedGroupNode$,
  fn: (group, cls) =>
    group
      ? {
          groupId: group.id,
          classifierId: cls.id,
          classifierVersion: cls.version,
        }
      : null,
}).filter({ fn: isNotEmpty })

guard({
  clock: dependencyRemoved,
  filter: componentMounted$.map(mounted => mounted),
  target: refetchProperties,
})

sample({
  clock: merge([
    selectedGroupNode$.updates,
    inclusionStatusEvent,
    componentMounted$.updates.filter({ fn: value => value }),
  ]),
  source: combine({
    selectedGroup: selectedGroupNode$,
    fetchedGroupsMap: fetchedGroupsMap$,
    currentClassifier: currentUserClassifier$,
  }),
}).watch(({ fetchedGroupsMap, selectedGroup, currentClassifier }) => {
  if (!fetchedGroupsMap || !selectedGroup || !currentClassifier) {
    return
  }

  const groupId = getSourceGroupId(selectedGroup)

  if (selectedGroup && !fetchedGroupsMap[selectedGroup?.id]) {
    refetchProperties({
      groupId,
      classifierId: currentClassifier.id,
      classifierVersion: currentClassifier.version,
    })
  }
})

guard({
  source: refetchProperties,
  filter: isComponentMounted,
  target:
    smApi.UserClassifierGroup.Property.List.fetchStandardSizeProperties
      .defaultContext.submit,
})
