import * as smApi from '@gmini/sm-api-sdk'

import { createEvent, createStore, sample } from 'effector'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'

export const startGrouping = smApi.DynamicGroup.start.createContext()
export const checkStatusGrouping = smApi.DynamicGroup.status.createContext()

export const resetGroupingStatus = createEvent<void>()

sample({
  clock: [startGrouping.doneData, checkStatusGrouping.doneData],
  source: currentUserClassifier$,
  fn: async (cls, params) => {
    const { classifierId, classifierVersion, status } =
      params as smApi.DynamicGroupStatus.Data

    if (classifierId !== cls?.id) {
      resetGroupingStatus()
      return
    }
    if (status === 'InProgress') {
      await new Promise(resolve => setTimeout(resolve, 2000))

      checkStatusGrouping({
        classifierId,
        version: classifierVersion,
      })
    }
  },
})

export const groupingProcessStatus$ = createStore<
  smApi.DynamicGroupStatus.Data['status'] | null
>(null)
  .on(
    [startGrouping.doneData, checkStatusGrouping.doneData],
    (state, params) => (params as smApi.DynamicGroupStatus.Data).status,
  )
  .reset(resetGroupingStatus)

sample({
  clock: smApi.DynamicGroup.create.doneData,
  fn: ({ classifierVersion, classifierId }) => {
    smApi.UserClassifierTree.DynamicGroupItem.getList.defaultContext({
      classifierId,
      classifierVersion,
    })
  },
})
