import React, { memo, useMemo } from 'react'
// import { useResizeDetector } from 'react-resize-detector'

// import { Tooltip } from '@gmini/ui-kit'

import { Shevron, VirtualStandardSize } from '@gmini/ui-kit/lib/icons'

import {
  FolderGrouping,
  FolderDynamicGeneratedChild,
  FolderGroupingChild,
  FolderDynamicGenerated,
  VirtualStandardSizeWithSum,
} from '@gmini/ui-kit'

import { ReportFlatNode } from '../model/treeModel'

// import { EstimationReport } from '@gmini/sm-api-sdk/lib/EstimationApi'

import { getIcon, getIconDynamic } from './getTableIcon'

import {
  ArrowWrapper,
  ExpandButton,
  TableNodeCell,
  TableNodeIcon,
  TableNodeText,
  TableNodeWrapper,
  // TooltipNodeText,
} from './ElementCell.styled'
// import { DimensionText, HeaderText } from './TableContent.styled'

export const ElementContent = memo<{
  item: ReportFlatNode
  parentEntityName: string
  hasGroupingChild: boolean
  hasGroupingBranch: boolean
  hasGroupingCurrentGroup: boolean
  virtualStandardSize: boolean
  hasSimpleFieldFilter: boolean
  onExpandClick?: () => void
  isExpanded: boolean
  hover?: boolean
  onMouseOver?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}>(
  ({
    item,
    parentEntityName,
    hasGroupingChild,
    hasGroupingBranch,
    hasGroupingCurrentGroup,
    virtualStandardSize,
    hasSimpleFieldFilter,
    onExpandClick,
    isExpanded,
    hover,
    onMouseLeave,
    onMouseOver,
  }) => {
    // const [textIsOver, setTextIsOver] = useState(false)
    // const paddingLeft = getIndent(item.path.length)

    // const { width: inputWidth, ref: cellRef } = useResizeDetector()

    // const { hover, onMouseLeave, onMouseOver } = useHoverWithRef(textRef)

    // const { hover: tooltipOpen } = useWithDelay(
    //   { hover },
    //   {
    //     enterDelay: 200,
    //     leaveDelay: 200,
    //   },
    // )

    const paddingLeft = useMemo(() => {
      let indent = 0
      for (let i = 0; i < item.path.length; i++) {
        i ? (indent += 19) : (indent += 5)
      }
      return indent
    }, [item.path.length])

    // React.useEffect(() => {
    //   if (textWidth && inputWidth && textWidth + paddingLeft >= inputWidth) {
    //     setTextIsOver(true)
    //   } else {
    //     setTextIsOver(false)
    //   }
    // }, [inputWidth, item.path.length, paddingLeft, textWidth])

    // const dependencyText = React.useMemo(
    //   () =>
    //     EstimationReport.ReportResult.TreeBimItem.is(item.node) ? (
    //       <>
    //         <HeaderText>Зависимость:</HeaderText>
    //         <DimensionText>{`${' '}${parentEntityName}`}</DimensionText>
    //       </>
    //     ) : (
    //       ''
    //     ),
    //   [item.node, parentEntityName],
    // )

    const icon = React.useMemo(() => {
      //TODO Придумать более элегантный способ разрулить эти условия
      if (item.node.elementType === 'BimStandardSize' && hasSimpleFieldFilter) {
        return <VirtualStandardSizeWithSum />
      }

      if (hasGroupingBranch) {
        if (hasGroupingCurrentGroup) {
          return <FolderGrouping color='#4C5ECF' />
        }

        if (hasGroupingChild) {
          return <FolderGroupingChild color='#4C5ECF' />
        }

        if (hasGroupingChild) {
          return <FolderDynamicGeneratedChild color='#4C5ECF' />
        }

        return getIconDynamic(item.node.elementType)
      }

      if (item.node.elementType === 'DynamicGeneratedGroup') {
        return <FolderDynamicGenerated opacity={0.5} />
      }

      if (
        item.node.elementType === 'DynamicBaseGroup' ||
        item.source?.type === 'UserClassifierGroup'
      ) {
        return <FolderDynamicGenerated opacity={1} />
      }

      if (item.node.elementType === 'UserClassifierGroup' && hasGroupingChild) {
        return <FolderGroupingChild color='#4C5ECF' />
      }

      if (virtualStandardSize) {
        return <VirtualStandardSize />
      }

      return getIcon(item.node.elementType)
    }, [
      hasGroupingBranch,
      item.node.elementType,
      item.source?.type,
      hasGroupingChild,
      hasSimpleFieldFilter,
      virtualStandardSize,
      hasGroupingCurrentGroup,
    ])

    // const tooltipText = React.useMemo(
    //   () =>
    //     textIsOver ? (
    //       <>
    //         {dependencyText}
    //         <TooltipNodeText>{item.node.name}</TooltipNodeText>
    //       </>
    //     ) : (
    //       dependencyText
    //     ),
    //   [dependencyText, item.node.name, textIsOver],
    // )

    return (
      <TableNodeCell
        onDoubleClick={onExpandClick}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
      >
        <TableNodeWrapper paddingLeft={paddingLeft}>
          <ExpandButton visible={!item.leaf} onClick={onExpandClick}>
            <ArrowWrapper expanded={isExpanded} hovered={hover}>
              <Shevron />
            </ArrowWrapper>
          </ExpandButton>
          <TableNodeIcon>{icon}</TableNodeIcon>
          <TableNodeText>{item.node.name}</TableNodeText>
        </TableNodeWrapper>
      </TableNodeCell>
    )
  },
)

ElementContent.displayName = 'ElementContent'
