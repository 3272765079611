import { Warning } from '@gmini/ui-kit'

import {
  SimpleCode,
  Row,
  RowRightSide,
  RowLeftSide,
  WarningIcon,
  Alert,
  AlertContent,
  AlertText,
} from './HelpDialog.styled'

export const ExampleTabContent = () => (
  <>
    <Row>
      <RowLeftSide flex='2'>Количество элементов</RowLeftSide>
      <RowRightSide flex='5'>
        <SimpleCode>1</SimpleCode>
      </RowRightSide>
    </Row>
    <Row>
      <RowLeftSide flex='2'>Длина (метры)</RowLeftSide>
      <RowRightSide flex='5'>
        <SimpleCode>Размеры.Длина</SimpleCode> /1000
      </RowRightSide>
    </Row>
    <Row>
      <RowLeftSide flex='2'>Объем</RowLeftSide>
      <RowRightSide flex='5'>
        <SimpleCode>Размеры.Объем</SimpleCode>
      </RowRightSide>
    </Row>
    <Row>
      <RowLeftSide flex='2'>Площадь</RowLeftSide>
      <RowRightSide flex='5'>
        <SimpleCode padding='8px'>Размеры.Площадь</SimpleCode> или{' '}
        <SimpleCode padding='8px'>Размеры.Ширина</SimpleCode> *{' '}
        <SimpleCode padding='8px'>Размеры.Высота</SimpleCode>
      </RowRightSide>
    </Row>
    <Row>
      <RowLeftSide flex='2'>Откосы</RowLeftSide>
      <RowRightSide flex='5'>
        <SimpleCode>Размеры.Высота</SimpleCode>*2/1000 +
        <SimpleCode>Размеры.Ширина</SimpleCode> /1000
      </RowRightSide>
    </Row>

    <Row>
      <RowLeftSide flex='2'>Длина в шт </RowLeftSide>
      <RowRightSide flex='5'>
        <SimpleCode padding='8px 18px'>1*310/1000/3</SimpleCode>
      </RowRightSide>
    </Row>

    <Alert>
      <WarningIcon>
        <Warning />
      </WarningIcon>
      <AlertContent>
        <AlertText>
          Если вам необходимо ввести <b>1</b>, а подсказка предлагает ненужные
          варианты вроде <b>Ширина1, Длина1</b> и т.п., то попробуйте ввести{' '}
          <b>1</b> и поставить оператор или пробел.
        </AlertText>{' '}
        <AlertText>
          Например,
          <b>&quot;1*15&quot;</b> - таким образом вы подсчитаете количество
          элементов типоразмера и умножите на 15
        </AlertText>
      </AlertContent>
    </Alert>
  </>
)
